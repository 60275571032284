import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Button, Select, Spin } from "antd";
import Iconfinder from "../../Images/iconfinder_click.svg";
import Vector from "../../Images/Vector.svg";
import IconfinderClock from "../../Images/iconfinder_clock.svg";
import { Form, Field } from "react-final-form";
import {
  GET_SURVEY_BY_ID,
  GET_REPORTS_HEADER,
  GET_REPORTS_DATA,
  GET_REPORTS_DATA_NEW,
} from "../../GraphQL/queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import copylink1 from "../../Images/copylink1.png";
import Import from "../../Images/Import.png";
import getExport from "./export";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { printVar } from "./var";
import TextBody from "./textBody";
import ChoiceBody from "./choiceBody";
import { timeConvert } from "./timeConvert";
import ResponsesModal from "./responses";

const Report = ({ history, match, agencyData, isPublic }) => {
  let surveyId = match.params.surveyId.split(":")[1];
  let agencyId = match.params.agencyId.split(":")[1];

  const [btnLoad, setBtnLoad] = useState(false);
  const [showResponses, toggleResponseModal] = useState(false);

  let [departmentIds, setdepartmentIds] = useState([]);

  let [locationtIds, setlocationtIds] = useState([]);

  let [seniorityLevelIds, setseniorityLevelIds] = useState([]);

  let [roleIds, setRoleIds] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState();

  let [loadReportHeader, reportHeader] = useLazyQuery(GET_REPORTS_HEADER, {
    variables: {
      surveyId,
      departmentIds: departmentIds,
      locationIds: locationtIds,
      seniorityLevelIds: seniorityLevelIds,
      roleIds: roleIds,
    },
    fetchPolicy: "cache-and-network",
  });

  let [loadReports, reports] = useLazyQuery(GET_REPORTS_DATA_NEW, {
    variables: {
      surveyId,
      departmentIds: departmentIds,
      locationIds: locationtIds,
      seniorityLevelIds: seniorityLevelIds,
      roleIds: roleIds,
      skip: 0,
      limit: 100,
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    loadReportHeader();
    loadReports();
  }, []);

  const { Option } = Select;

  const onSubmit = (values) => {
    console.log(values);
  };

  let { loading, data } = useQuery(GET_SURVEY_BY_ID, {
    variables: {
      surveyId,
    },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div className="loading-wrap">
        <Spin
          style={{ float: "center" }}
          indicator={<LoadingOutlined />}
          size="large"
        />
      </div>
    );

  let reportData;
  if (reports.data) reportData = reports.data.listQuestionsUnderSurvey;

  const beforeprints = () => {
    printVar({
      departmentIds: departmentIds,
      locationIds: locationtIds,
      seniorityLevelIds: seniorityLevelIds,
      roleIds: roleIds,
    });
    history.push(`/admin/agency/:${agencyId}/:${surveyId}/print`);
  };

  return (
    <div className="report-wrap">
      <Row gutter={16}>
        <Col span={6} className="report-left">
          <div className="Button-wrap">
            <Button
              style={{ background: "#DD4B4B", color: "#fff" }}
              onClick={(e) => beforeprints()}
              disabled={!reportHeader.data && reports.loading && !reports.data}
            >
              <img src={copylink1} alt="Print" />
              &nbsp;Print Report
            </Button>
            <Button
              style={{ background: "black", color: "#fff" }}
              loading={btnLoad}
              onClick={(e) => {
                getExport(
                  surveyId,
                  departmentIds,
                  roleIds,
                  seniorityLevelIds,
                  locationtIds,
                  data.getSurveyById.name,
                  setBtnLoad
                );
              }}
            >
              {!btnLoad && <img src={Import} alt="Import" />}
              &nbsp;Export Result
            </Button>
          </div>
          <div className="Filter-wrap">
            <label className="filter-title"> FILTER BY </label>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "15px" }}>
                      <Field
                        name="department"
                        render={({ input, meta }) => {
                          return (
                            <div id="department">
                              <Select
                                mode="multiple"
                                showArrow
                                filterOption
                                optionFilterProp={"label"}
                                allowClear
                                getPopupContainer={() =>
                                  document.getElementById("department")
                                }
                                maxTagCount={2}
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                }}
                                placeholder="Department"
                                onChange={(e) => {
                                  setdepartmentIds((departmentIds = e));
                                  loadReportHeader();
                                  loadReports();
                                }}
                                options={
                                  isPublic
                                    ? agencyData &&
                                      agencyData.listDepartment.map(
                                        (department) => ({
                                          label: department.name,
                                          value: department._id,
                                        })
                                      )
                                    : data &&
                                      data.getSurveyById.departments.map(
                                        (department) => ({
                                          label: department.name,
                                          value: department._id,
                                        })
                                      )
                                }
                                onBlur={(e) => input.onBlur(e)}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <Field
                        name="location"
                        render={({ input, meta }) => (
                          <div id="loc">
                            <Select
                              mode="multiple"
                              showArrow
                              filterOption
                              optionFilterProp={"label"}
                              maxTagCount={2}
                              allowClear
                              getPopupContainer={() =>
                                document.getElementById("loc")
                              }
                              style={{ width: "100%", borderRadius: "4px" }}
                              placeholder="Location"
                              onChange={(e) => {
                                setlocationtIds((locationtIds = e));
                                loadReportHeader();
                                loadReports();
                              }}
                              options={
                                isPublic
                                  ? agencyData &&
                                    agencyData.listLocation.map((data) => ({
                                      label: data.name,
                                      value: data._id,
                                    }))
                                  : data &&
                                    data.getSurveyById.locations.map(
                                      (data) => ({
                                        label: data.name,
                                        value: data._id,
                                      })
                                    )
                              }
                              onBlur={(e) => input.onBlur(e)}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <Field
                        name="role"
                        render={({ input, meta }) => (
                          <div id="Role">
                            <Select
                              mode="multiple"
                              showArrow
                              filterOption
                              optionFilterProp={"label"}
                              maxTagCount={2}
                              getPopupContainer={() =>
                                document.getElementById("Role")
                              }
                              allowClear
                              style={{ width: "100%", borderRadius: "4px" }}
                              placeholder="Role"
                              onChange={(e) => {
                                setRoleIds((roleIds = e));
                                loadReportHeader();
                                loadReports();
                              }}
                              onBlur={(e) => input.onBlur(e)}
                              options={
                                isPublic
                                  ? agencyData &&
                                    agencyData.listRole.map((data) => ({
                                      label: data.name,
                                      value: data._id,
                                    }))
                                  : data &&
                                    data.getSurveyById.roles.map((data) => ({
                                      label: data.name,
                                      value: data._id,
                                    }))
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <Field
                        name="seniorityLevel"
                        render={({ input, meta }) => (
                          <div id="seniorityLevel">
                            <Select
                              mode="multiple"
                              showArrow
                              filterOption
                              optionFilterProp={"label"}
                              maxTagCount={2}
                              allowClear
                              getPopupContainer={() =>
                                document.getElementById("seniorityLevel")
                              }
                              style={{ width: "100%", borderRadius: "4px" }}
                              placeholder="Seniority Level"
                              onChange={(e) => {
                                setseniorityLevelIds((seniorityLevelIds = e));
                                loadReportHeader();
                                loadReports();
                              }}
                              options={
                                isPublic
                                  ? agencyData &&
                                    agencyData.listSeniority.map((data) => ({
                                      label: data.name,
                                      value: data._id,
                                    }))
                                  : data &&
                                    data.getSurveyById.seniorityLevels.map(
                                      (data) => ({
                                        label: data.name,
                                        value: data._id,
                                      })
                                    )
                              }
                              onBlur={(e) => input.onBlur(e)}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </Col>
        <Col span={18}>
          <div className="report-right">
            <div className="report-wedget">
              {isPublic ? (
                <Row>
                  <Col span={12}>
                    <img src={Vector} alt="Vector"></img>
                    <label>Total Responses</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.totalResponse}
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={10}>
                    <img src={IconfinderClock} alt="IconfinderClock"></img>
                    <label>Avg. Time to Complete</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "40px" }}>
                        {timeConvert(
                          reportHeader.data.reportsHeader.avgTimeTookForSurvey
                        )}
                      </p>
                    )}
                  </Col>
                  {/* <Divider type="vertical" />
                  <Col span={7}>
                    <label>Completion Rate</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.completion}%
                      </p>
                    )}
                  </Col> */}
                </Row>
              ) : (
                <Row>
                  <Col span={5}>
                    <img src={Iconfinder} alt="iconFinder"></img>
                    <label>Pending</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.pendingEmployeesCount}
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={5}>
                    <img src={Vector} alt="Vector"></img>
                    <label>Completed</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {
                          reportHeader.data.reportsHeader
                            .employeesRespondedCount
                        }
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={6}>
                    <img src={IconfinderClock} alt="IconfinderClock"></img>
                    <label>Avg. Time to Complete</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "40px" }}>
                        {timeConvert(
                          reportHeader.data.reportsHeader.avgTimeTookForSurvey
                        )}
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={6}>
                    <label>Completion Rate</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.completion}%
                      </p>
                    )}
                  </Col>
                </Row>
              )}
            </div>
            <div>
              {reportData &&
                reportData.data.map((question, i) => {
                  return (
                    <Row>
                      <Col span={24}>
                        <div className="report-survey-detail">
                          <div className="report-head h4">
                            <h4>
                              Question{" "}
                              {(i = 0 ? i : i + 1) && (i < 10 ? "0" + i : i)}
                            </h4>
                          </div>
                          <div className="report-head h2">
                            <h2>{question.questionText}</h2>
                          </div>
                          <label className="report-label">
                            Answered: {question.employeesAttemptedQuestionCount}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Skipped:{" "}
                            {question.employeesAttemptedSurveyCount -
                              question.employeesAttemptedQuestionCount}
                          </label>{" "}
                        </div>
                        <div>
                          {question.type === "text" && (
                            <div>
                              <TextBody
                                isPublic={isPublic}
                                answers={question.employeeAnswers}
                                totalResponses={question.employeeAnswersCount}
                                onShowAllResponses={() => {
                                  setSelectedQuestion(question._id);
                                  setTimeout(() => {
                                    toggleResponseModal(true);
                                  }, 100);
                                }}
                              />
                            </div>
                          )}
                          {question.type === "choice" && (
                            <div>
                              <ChoiceBody
                                answers={question.answers}
                                answerdCount={
                                  question.employeesAttemptedQuestionCount
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              <ResponsesModal
                open={showResponses}
                toggleModal={toggleResponseModal}
                isPublic={isPublic}
                surveyId={surveyId}
                questionId={selectedQuestion}
                selectedDepartment={departmentIds}
                selectedLocation={locationtIds}
                selectedRole={roleIds}
                selectedSeniorityLevel={seniorityLevelIds}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Report);
