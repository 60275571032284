import React, { useState } from 'react'
import { Layout } from 'antd';
import HeaderLogo from '../../Images/header-logo.png'
import { useReactiveVar } from '@apollo/client';
import { currentPage } from './header-reactive-variables'
import { ChangePassword } from './changepassword'

const HeaderWrap = (props) => {
  
  const page = useReactiveVar(currentPage);

  return (
    <Layout.Header className='header'>
      <div className='header-logo-wrap'>
        <div className='logo'>
          <img src={HeaderLogo} alt='header-logo' />
        </div>
        <h2>{page}</h2>
      </div>
      <ChangePassword />
    </Layout.Header>
  )
}
export default HeaderWrap;