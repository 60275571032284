import React, { useState } from "react";
import { Input, Button, Row, Col } from "antd";
import Logo from "../../Images/logo.png";
import { Form, Field } from "react-final-form";
import LeftPane from "./left-pane";
import { validator } from "./operations";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../GraphQL/mutations";
import { authVar } from "../../Routes/ReactiveVariables";
const Login = () => {
  const [login, { data, loading }] = useMutation(LOGIN_USER);
  const [error, setError] = useState("");
  const submitData = async (data) => {
    try {
      let res = await login({
        variables: {
          email: data.email,
          password: data.password,
        },
      });
      localStorage.setItem("@authToken", res.data.login.token);
      authVar({
        loaded: true,
        auth: true,
      });
    } catch (err) {
      setError("Invalid email or password");
      console.log(err);
    }
  };
  return (
    <Row className="login-wrap">
      <LeftPane />
      <Col span={12} className="right-wrap">
        <div className="logo-wrap">
          <img src={Logo} alt="logo" />
        </div>
        <div className="form-wrap">
          <h2>Welcome Back.</h2>
          <Form
            onSubmit={(data) => submitData(data)}
            validate={(values) => validator(values)}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="field-item">
                  <Field autoFocus name="email">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          size="large"
                          component="input"
                          type="email"
                          placeholder="Email"
                          onChange={(e) => {
                            setError("");
                            input.onChange(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="field-item">
                  <Field name="password">
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          error={true}
                          component="input"
                          type="password"
                          placeholder="Password"
                          size="large"
                          onChange={(e) => {
                            setError("");
                            input.onChange(e.target.value);
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span className="error">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <p style={{ textAlign: "center", color: "red" }}>{error}</p>
                <div className="field-item">
                  <Button
                    type="submit"
                    block
                    size="large"
                    loading={loading}
                    disabled={submitting || pristine}
                    onClick={() => handleSubmit()}
                  >
                    Login
                  </Button>
                </div>
                <div className="link-wrap">
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
              </form>
            )}
          />
        </div>
      </Col>
    </Row>
  );
};
export default Login;
