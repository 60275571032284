import React from 'react' 
import { Spin  } from 'antd'
import {useQuery} from '@apollo/client'
import {GET_SURVEY_QUESTIONS} from '../../GraphQL/queries'
import { LoadingOutlined } from "@ant-design/icons";
const Questions = ({surveyId}) =>{
    const {data,loading,error} = useQuery(GET_SURVEY_QUESTIONS,{
        variables:{
            surveyId
        }
    })
    if (loading)  return (
        <div className="loading-wrap">
          <Spin style={{ float: "center" }} indicator={<LoadingOutlined />} size='large' />
        </div>
      );
    return (
        <div className='question-wrap'>
            <h1>Workplace Information</h1>
            {data && data.listQuestionsUnderSurvey.data.map((question,index) =>{
                return(
                    <div className='question-item'>
                        <div className='question-num'>
                            <div className='question-count-wrap'>
                                <h2>{index+1}</h2>
                            </div>
                        </div>
                        <div className='question-content'>
                            <h2>{question.questionText}</h2>
                            {question.type==='text' && (<div className='question-text-input'/>)}
                            {question.type==='choice' && (<div className='question-mcq-wrap'>
                                {question.answers.map((answer) =>{
                                    return(
                                        <div className='question-mcq'>
                                            <div className='question-mcq-radio'/>
                                            <h4>{answer.answerText}</h4>
                                        </div>
                                    )
                                })}
                            </div>)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default Questions