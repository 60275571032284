import React, { useEffect } from "react";
import Iconfinder from "../../Images/iconfinder_click.svg";
import Vector from "../../Images/Vector.svg";
import IconfinderClock from "../../Images/iconfinder_clock.svg";
import CalenderIcon from "../../Images/cal-icon.png";
import GlobIcon from "../../Images/glob-icon.png";
import moment from "moment";
import { printVar, showSideNav } from "../SurveyDetail/var";
import { withRouter } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Row, Col, Divider } from "antd";
import TextBody from "./textBody";
import ChoiceBody from "./choiceBody";
import {
  GET_SURVEY_BY_ID,
  GET_REPORTS_HEADER,
  GET_REPORTS_DATA,
  GET_REPORTS_DATA_NEW,
} from "../../GraphQL/queries";
import { useQuery } from "@apollo/client";
import { timeConvert } from "../SurveyDetail/timeConvert";

const PrintReport = ({ match, history }) => {
  showSideNav(false);
  let surveyId = match.params.surveyId.split(":")[1];
  let agencyId = match.params.agencyId.split(":")[1];

  const printVariable = useReactiveVar(printVar);

  const surveyData = useQuery(GET_SURVEY_BY_ID, {
    variables: {
      surveyId: surveyId,
    },
  });
  const isPublic = surveyData.data
    ? surveyData.data.getSurveyById.anonymous
    : false;
  const reportHeader = useQuery(GET_REPORTS_HEADER, {
    variables: {
      surveyId: surveyId,
      departmentIds: printVariable.departmentIds,
      locationIds: printVariable.locationIds,
      seniorityLevelIds: printVariable.seniorityLevelIds,
      roleIds: printVariable.roleIds,
    },
  });

  const reports = useQuery(GET_REPORTS_DATA_NEW, {
    variables: {
      surveyId: surveyId,
      departmentIds: printVariable.departmentIds,
      locationIds: printVariable.locationIds,
      seniorityLevelIds: printVariable.seniorityLevelIds,
      roleIds: printVariable.roleIds,
      skip: 0,
      limit: 100,
    },
  });

  useEffect(() => {
    const onPrint = () => {
      const surveyURI = `${window.location.protocol}//${window.location.host}/admin/agency/:${agencyId}/:${surveyId}/report`;
      window.print();
      window.location.replace(surveyURI);
    };
    setTimeout(() => {
      reports && reports.data && reportHeader && reportHeader.data && onPrint();
    }, 2000);
  }, [reports, reportHeader, agencyId, surveyId]);

  let reportData;
  if (reports && reports.data)
    reportData = reports.data.listQuestionsUnderSurvey;
  return (
    <div>
      {surveyData && surveyData.data && (
        <div className="survey-detail-header-wrap" style={{ padding: "25px" }}>
          <div className="survey-detail-header-content">
            <h1>{surveyData.data.getSurveyById.name}</h1>
            <div className="survey-detail-header-sub-text">
              <div
                className="survey-detail-sub-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={GlobIcon}
                  alt="globe icon"
                  style={{ objectit: "contain", marginRight: "10px" }}
                />
                <p style={{ margin: "0" }}>
                  {surveyData.data.getSurveyById.agency.agencyName}/
                  {surveyData.data.getSurveyById.departments
                    .map((item) => item.name)
                    .join()}
                </p>
              </div>
              <div
                className="survey-detail-sub-text"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={CalenderIcon}
                  alt="calender icon"
                  style={{ objectit: "contain", marginRight: "10px" }}
                />
                <p style={{ margin: "0" }}>
                  {moment(
                    new Date(
                      Number(surveyData.data.getSurveyById.surveyEndDate)
                    )
                  ).format("MMM DD YYYY hh:mm a")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Row>
        <Col span={24}>
          <div className="report-right">
            <div className="report-wedget">
              {surveyData.data && surveyData.data.getSurveyById.anonymous ? (
                <Row>
                  <Col span={10}>
                    <img src={Vector} alt="Vector"></img>
                    <label>Total Responses</label>
                    <p style={{ fontWeight: "700", fontSize: "50px" }}>
                      {reportHeader.data.reportsHeader.totalResponse}
                    </p>
                  </Col>
                  <Divider type="vertical" />
                  <Col span={12}>
                    <img src={IconfinderClock} alt="IconfinderClock"></img>
                    <label>Avg. Time to Complete</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "30px" }}>
                        {timeConvert(
                          reportHeader.data.reportsHeader.avgTimeTookForSurvey
                        )}
                      </p>
                    )}
                  </Col>
                  {/* <Divider type="vertical" />
                  <Col span={7}>
                    <label>Completion Rate</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.completion}%
                      </p>
                    )}
                  </Col> */}
                </Row>
              ) : (
                <Row>
                  <Col span={5}>
                    <img src={Iconfinder} alt="iconFinder"></img>
                    <label>Pending</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.pendingEmployeesCount}
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={5}>
                    <img src={Vector} alt="Vector"></img>
                    <label>Completed</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {
                          reportHeader.data.reportsHeader
                            .employeesRespondedCount
                        }
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={6}>
                    <img src={IconfinderClock} alt="IconfinderClock"></img>
                    <label>Avg. Time to Complete</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "30px" }}>
                        {timeConvert(
                          reportHeader.data.reportsHeader.avgTimeTookForSurvey
                        )}
                      </p>
                    )}
                  </Col>
                  <Divider type="vertical" />
                  <Col span={6}>
                    <label>Completion Rate</label>
                    {reportHeader && reportHeader.data && (
                      <p style={{ fontWeight: "700", fontSize: "50px" }}>
                        {reportHeader.data.reportsHeader.completion}%
                      </p>
                    )}
                  </Col>
                </Row>
              )}
            </div>
            <div>
              {reportData &&
                reportData.data.map((question, i) => {
                  return (
                    <Row>
                      <Col span={24}>
                        <div className="report-survey-detail">
                          <div className="report-head h4">
                            <h4>Question {i + 1}</h4>
                          </div>
                          <div className="report-head h2">
                            <h5>{question.questionText}</h5>
                          </div>
                          <label className="report-label">
                            Answered: {question.employeesAttemptedQuestionCount}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Skipped:{" "}
                            {question.employeesAttemptedSurveyCount -
                              question.employeesAttemptedQuestionCount}
                          </label>{" "}
                          {/* <label className="report-label">skipped: {question.skipedCount}</label> */}
                        </div>
                        <div>
                          {question.type === "text" && (
                            <div>
                              <TextBody
                                isPublic={isPublic}
                                answers={question.employeeAnswers}
                                totalResponses={question.employeeAnswersCount}
                                onShowAllResponses={() => {
                                  // setSelectedQuestion(question._id);
                                  // setTimeout(() => {
                                  //   toggleResponseModal(true);
                                  // }, 100);
                                }}
                              />
                            </div>
                          )}
                          {question.type === "choice" && (
                            <div>
                              <ChoiceBody
                                answers={question.answers}
                                answerdCount={
                                  question.employeesAttemptedQuestionCount
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(PrintReport);
