export const timeConvert = (duration) => {
  let time = "";
  if (duration <= 0) {
    time = "0 s";
  } else {
    let hours = Math.floor(duration / (60 * 60));
    if (hours > 0) {
      duration = duration - hours * 60 * 60;
      time = time.concat(hours.toString(), "h ");
    }

    let mins = Math.floor(duration / 60);
    if (mins > 0) {
      duration = duration - mins * 60;
      time = time.concat(mins.toString(), "m ");
    }

    let secs = Math.floor(duration);
    if (hours < 100 && secs > 0) {
      duration = duration - secs;
      time = time.concat(secs.toString(), "s ");
    }

    // if (duration > 0) time = time.concat(duration.toString(), "ms");
  }

  console.log("time", time);
  return time;
};
