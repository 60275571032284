import React from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import logo from "../../Images/wathen-logo.png";
const Footer = ({ setIndex, index, showNavIcons, showNext }) => {
  return (
    <footer className="survey_footer">
      <div>
        <h4>
          {" "}
          <img src={logo} width="40" />{" "}
          <span>
            {" "}
            Powered by
            <br /> HubSpire
          </span>
        </h4>
      </div>
      {showNavIcons && (
        <div className="arrows">
          {index != 0 && (
            <div
              className="left-arrows"
              onClick={() => setIndex(index - 1)}
              tabindex={0}
              onKeyDown={(e) => {
                if (e.code === "Enter") setIndex(index - 1);
              }}
            >
              <ArrowLeftOutlined />
            </div>
          )}
          <div
            className="right-arrows"
            onClick={() => showNext()}
            tabindex={0}
            onKeyDown={(e) => {
              if (e.code === "Enter") { showNext(); }
            }}
          >
            <ArrowRightOutlined />
          </div>
        </div>
      )}
    </footer>
  );
};
export default Footer;
