import React, { useState } from "react";
import {
  Table,
  Avatar,
  Menu,
  Dropdown,
  Button,
  Spin,
  notification,
} from "antd";
import { EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EMPLOYEES_BY_SURVEY } from "../../GraphQL/queries";
import { RESET_SURVEY_EMPLOYEES } from "../../GraphQL/mutations";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

const colours = [
  "#ffa940",
  "#ff4d4f",
  "#fa541c",
  "#ffd666",
  "#ffec3d",
  "#d3f261",
  "#69c0ff",
  "#13c2c2",
  "#95de64",
  "#ad6800",
  "#fffb8f",
  "#5b8c00",
  "#ffa39e",
  "#ffd8bf",
  "#ffe7ba",
  "#1d39c4",
  "#531dab",
  "#eb2f96",
];
const colour = () => colours[Math.floor(Math.random() * colours.length)];
const Employees = ({ history, surveyId, agencyId }) => {
  const [empCurrentPage, setCurrentPage] = useState(1);
  const { loading, data } = useQuery(GET_EMPLOYEES_BY_SURVEY, {
    variables: {
      surveyId: surveyId,
      limit: 20,
      skip: (empCurrentPage - 1) * 20,
    },
    fetchPolicy: "network-only",
  });

  const [resetEmployee] = useMutation(RESET_SURVEY_EMPLOYEES);

  const handleReset = async (employeeId) => {
    try {
      const resp = await resetEmployee({
        variables: {
          employeeId: employeeId,
          surveyId: surveyId,
          agencyId: agencyId,
        },
        refetchQueries: [
          { query: GET_EMPLOYEES_BY_SURVEY, variables: { surveyId: surveyId } },
        ],
      });

      console.log("response", resp);
      if (resp.data.resetSurveyForEmployees.status)
        notification.success({
          message: "Employee has been reset successfully",
          duration: 3,
        });
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Error", duration: 3 });
    }
  };
  if (loading)
    return (
      <div className="loading-wrap">
        <Spin
          style={{ float: "center" }}
          indicator={<LoadingOutlined />}
          size="large"
        />
      </div>
    );

  const columns = [
    {
      title: "Name",
      dataIndex: "employeeDetails",
      render: (record) => (
        <div className="icon-name">
          <Avatar size="middle" style={{ backgroundColor: colour() }}>
            {record.fullName.charAt(0).toUpperCase()}
          </Avatar>
          <h4>{record.fullName}</h4>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Phone",
      dataIndex: "employeeDetails",
      render: (record) => (
        <div>
          {/* <NumberFormat value={record.phone} displayType={'text'} format="(###) ###-#### " /> */}
          {record.phone === "null" ||
          record.phone == null ||
          record.phone === "EXEMPT" ? (
            "-"
          ) : (
            <NumberFormat
              value={record.phone}
              displayType={"text"}
              format="(###) ###-#### "
            />
          )}
        </div>
      ),
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "employeeDetails",
      render: (record) => record.email,
      width: "30%",
    },
    {
      title: "EMP ID",
      dataIndex: "employeeDetails",
      render: (record) => record.employeeId,
      width: "10%",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (record) => (
        <div>
          <Button
            className="status-tag"
            style={{
              backgroundColor: record === "scheduled" ? "#DD9200" : "#43875E",
            }}
          >
            <span style={{ color: "white" }}>
              {record === "scheduled" ? "Pending" : "Completed"}
            </span>
          </Button>
        </div>
      ),
      width: "10%",
    },
    {
      title: "",
      key: "",
      width: "10%",
      render: (obj) => (
        <Dropdown
          disabled={obj.status === "completed" ? false : true}
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => handleReset(obj.employeeDetails._id)}
              >
                Reset
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <a
            href
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="content-item-wrap employee-list">
      {data && (
        <Table
          bordered={false}
          columns={columns}
          dataSource={data.listEmployeeUnderSurvey.data}
          className="custom-table"
          pagination={{
            current: empCurrentPage,
            total: data.listEmployeeUnderSurvey.count,
            hideOnSinglePage: true,
            defaultPageSize: 20,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
      )}
    </div>
  );
};
export default withRouter(Employees);
