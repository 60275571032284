import React from "react";
import { Divider, Row, Col, Card, Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import overviewImage from "../../Images/Frame.png";
import overviewMetroLogo from "../../Images/overview-metro-logo.png";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

const style = { fontSize: "14px", color: "#605F5F" };
const style1 = { lineHeight: "5px", padding: "0.5px", marginTop: "15px" };

const Overview = (props) => {
  const data = props.data.getAgency;
  const contactPerson = data.contactPerson;
  let logo = null;
  if (!data.logo || data.logo.length === 0) logo = data.agencyName;
  return (
    <Row gutter={32}>
      <Col span={10}>
        <Card
          className="agency-card-wrap"
          style={{ width: 382 }}
          cover={
            <div className="agencyLogo">
              {data.logo && (
                <Avatar
                  size="large"
                  src={data.logo}
                  shape="square"
                  style={{
                    width: "131.5px",
                    height: "48.96px",
                    left: "30.5px",
                    top: "10.97px",
                  }}
                />
              )}
              {logo && (
                <Avatar
                  size="large"
                  icon={logo}
                  shape="square"
                  style={{
                    width: "131.5px",
                    height: "48.96px",
                    left: "30.5px",
                    top: "10.97px",
                  }}
                />
              )}
            </div>
          }
        >
          <div style={style}>
            <Row style={{ padding: "20px 0px" }}>
              <Col span={9}>
                <label>Name</label>
              </Col>
              <Col span={15}>{data.agencyName}</Col>
            </Row>
            <div className="border1"></div>
            <Row style={{ padding: "20px 0px" }}>
              <Col span={9}>
                <label>Address</label>
              </Col>
              <Col span={15}>
                <span>{data.address}</span>
              </Col>
            </Row>
            <div className="border2"></div>
            <Row style={{ padding: "20px 0px" }}>
              <Col span={9}>
                <label>Phone</label>
              </Col>
              <Col span={15}>
                {data.phone === "null" ||
                data.phone == null ||
                data.phone === "EXEMPT" ? (
                  "-"
                ) : (
                  <a href="tel:data.phone">
                    <NumberFormat
                      value={data.phone}
                      displayType={"text"}
                      format="(###) ###-#### "
                    />
                  </a>
                )}
              </Col>
            </Row>
            <div className="border3"></div>
            <Row style={{ padding: "20px 0px" }}>
              <Col span={9}>
                <label>Email</label>
              </Col>
              <Col span={15}>
                <a href="mailto:data.email">{data.email}</a>
              </Col>
            </Row>
            <div className="border4"></div>
            <Row style={{ padding: "20px 0px" }}>
              <Col span={9}>
                <label>Website</label>
              </Col>
              <Col span={15}>{data.website}</Col>
            </Row>
          </div>
        </Card>
        <div className="contactTitle">
          <p>Primary Contact</p>
        </div>
        <div className="contactCard">
          <Row>
            <Col span={3}>
              {contactPerson.profileImage ? (
                <Avatar
                  icon={contactPerson.profileImage}
                  src={contactPerson.profileImage}
                />
              ) : (
                <Avatar size="large" icon={<UserOutlined />} />
              )}
            </Col>
            <Col span={21} style={{ lineHeight: "18px" }}>
              <label>
                {contactPerson.firstName + " " + contactPerson.lastName}
              </label>
              <br />
              {contactPerson.email}
              <br />
              {contactPerson.phone === "null" ||
              contactPerson.phone == null ||
              contactPerson.phone === "EXEMPT" ? (
                "-"
              ) : (
                <NumberFormat
                  value={contactPerson.phone}
                  displayType={"text"}
                  format="(###) ###-#### "
                />
              )}
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={14}>
        <div className="overview-image">
          <img src={overviewImage} alt=""></img>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(Overview);
