import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPH_URL,
});
const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem("@authToken");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});
// GraphQL Init
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default client;
