import { gql } from "@apollo/client";
export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        _id
        firstName
        lastName
        email
        role
      }
      token
    }
  }
`;

export const IMPORT_EMPLOYEES = gql`
  mutation importEmployees($file: Upload!, $agencyId: String!) {
    importEmployees(file: $file, agencyId: $agencyId) {
      status
      message
      data {
        updatedEmployees
        createdEmployees
      }
    }
  }
`;

export const ADD_SURVEY = gql`
  mutation addSurvey(
    $agencyId: String!
    $name: String!
    $department: [String]
    $role: [String]
    $seniorityLevel: [String]
    $location: [String]
    $status: Boolean!
    $surveyEndDate: String
    $anonymous: Boolean
  ) {
    addSurvey(
      agencyId: $agencyId
      name: $name
      department: $department
      role: $role
      seniorityLevel: $seniorityLevel
      location: $location
      status: $status
      surveyEndDate: $surveyEndDate
      anonymous: $anonymous
    ) {
      name
      status
      surveyEndDate
    }
  }
`;

export const EDIT_SURVEY = gql`
  mutation editSurvey(
    $surveyId: String!
    $name: String!
    $department: [String]
    $role: [String]
    $seniorityLevel: [String]
    $location: [String]
    $status: Boolean!
    $surveyEndDate: String
    $anonymous: Boolean
  ) {
    editSurvey(
      surveyId: $surveyId
      name: $name
      department: $department
      role: $role
      seniorityLevel: $seniorityLevel
      location: $location
      status: $status
      surveyEndDate: $surveyEndDate
      anonymous: $anonymous
    ) {
      status
      message
    }
  }
`;

export const ADD_SURVEY_RESPONSE = gql`
  mutation addAnswers(
    $recaptcha: String!
    $surveyId: String!
    $anonymous: Boolean!
    $employeeId: String
    $departmentId: String
    $locationId: String
    $roleId: String
    $seniorityLevelId: String
    $data: [AnswerIn]!
    $surveyStartTime: String!
    $surveyEndTime: String!
    $timeDifference: Int
  ) {
    addAnswers(
      recaptcha: $recaptcha
      surveyId: $surveyId
      anonymous: $anonymous
      employeeId: $employeeId
      departmentId: $departmentId
      locationId: $locationId
      roleId: $roleId
      seniorityLevelId: $seniorityLevelId
      data: $data
      surveyStartTime: $surveyStartTime
      surveyEndTime: $surveyEndTime
      timeDifference: $timeDifference
    ) {
      status
      message
    }
  }
`;

export const RESET_SURVEY_EMPLOYEES = gql`
  mutation resetSurveyForEmployees(
    $agencyId: String!
    $surveyId: String!
    $employeeId: String!
  ) {
    resetSurveyForEmployees(
      agencyId: $agencyId
      surveyId: $surveyId
      employeeId: $employeeId
    ) {
      status
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $oldpassword: String!
    $newpassword: String!
    $confirmpassword: String!
  ) {
    changePassword(
      email: $email
      oldpassword: $oldpassword
      newpassword: $newpassword
      confirmpassword: $confirmpassword
    ) {
      status
      message
    }
  }
`;
