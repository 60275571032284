import {Redirect } from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import {authVar} from '../ReactiveVariables'
import Logo from "../../Images/logo.png";
const CheckAuth = () => {
    const auth = useReactiveVar(authVar);
    if(auth.loaded && auth.auth){
        return <Redirect to='/admin/agency' />
    }else if(auth.loaded && !auth.auth){
        return <Redirect to='/login' />
    }else{
        return <div className='site-load-wrp'><img src={Logo} alt='logo' /></div>
    }   
  }
export default CheckAuth;
  