import React, { useState } from "react";
import { Input, Button, Row, Col } from "antd";
import { notification } from "antd";
import Logo from "../../Images/logo.png";
import { Form, Field } from "react-final-form";
import LeftPane from "./left-pane";
import { validator } from "./operations";
import { useLazyQuery } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../GraphQL/queries";
const ForgotPassword = () => {
  const [getEmail, { loading, data, error }] = useLazyQuery(FORGOT_PASSWORD);
  const submitData = async (emailData) => {
    try {
      getEmail({
        variables: {
          email: emailData.email,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const notificationStatus = (data) => {
    if (data && data.forgotPassword.status) {
      return notification.success({
        message: "Password send",
        description: "Password sent to your registered mail.",
        duration: 3,
      });
    } else {
      return notification.error({
        message: "Invalid mail",
        description: "Enter mail not matched to register mail.",
        duration: 3,
      });
    }
  };
  return (
    <div>
      <Row className="login-wrap">
        <LeftPane />
        <Col span={12} className="right-wrap">
          <div className="logo-wrap">
            <img src={Logo} alt="logo" />
          </div>
          <div className="form-wrap">
            <h2>Forgot Password</h2>
            <Form
              onSubmit={(data) => submitData(data)}
              validate={(values) => validator(values)}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="field-item">
                    <Field autoFocus name="email">
                      {({ input, meta }) => (
                        <div>
                          <Input
                            {...input}
                            size="large"
                            component="input"
                            type="email"
                            placeholder="Email"
                          />
                          {meta.error && meta.touched && (
                            <span className="error">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="field-item">
                    <Button
                      type="submit"
                      block
                      size="large"
                      loading={loading}
                      disabled={submitting || pristine}
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="link-wrap">
                    <a href="/login">Back to Sign in</a>
                  </div>
                </form>
              )}
            />
          </div>
        </Col>
      </Row>
      {data && notificationStatus(data)}
      {error && notificationStatus()}
    </div>
  );
};
export default ForgotPassword;
