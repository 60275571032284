import React,{useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import NotImplemented from './Modules/NotImplemented'
import {authVar} from './Routes/ReactiveVariables'
import AuthRoute from './Routes/Auth'
import AdminRoutes from './Routes/AdminRoutes'
import { useReactiveVar } from '@apollo/client';
import Login from './Modules/Login'
import SurveyPage from './Modules/Survey'
import ForgotPassword from "./Modules/ForgotPassword";
import {useQuery} from '@apollo/client'
import {GET_CURRENT_USER} from './GraphQL/queries'
const App = () => {
  const auth = useReactiveVar(authVar);
  const currUser = useQuery(GET_CURRENT_USER,{
    onCompleted:(res) =>{
      authVar({
        loaded:true,
        auth:true
      })
    },
    onError:(err) =>{
      authVar({
        loaded:true,
        auth:false
      })
    }
  })
  return (
   
    <div className="App">
      <Router>
          <Switch>
            <Route exact path="/" component={AuthRoute} />
            <Route exact path="/survey/:surveyId" component={SurveyPage} />
            <Route  path="/admin" component={AdminRoutes}/>
            <Route exact path="/login">
                {auth.loaded && !auth.auth ? <Login/> : <Redirect to='/admin' />}
            </Route>
            <Route exact path="/forgot-password">
                {auth.loaded && !auth.auth ? <ForgotPassword/> : <Redirect to='/forgot-password' />}
            </Route>
          </Switch>
      </Router>
    </div>
  );
};
export default App


