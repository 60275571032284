import React from "react";
import { Table, Avatar, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

const colours = [
  "#ffa940",
  "#ff4d4f",
  "#fa541c",
  "#ffd666",
  "#ffec3d",
  "#d3f261",
  "#69c0ff",
  "#13c2c2",
  "#95de64",
  "#ad6800",
  "#fffb8f",
  "#5b8c00",
  "#ffa39e",
  "#ffd8bf",
  "#ffe7ba",
  "#1d39c4",
  "#531dab",
  "#eb2f96",
];
const colour = () => colours[Math.floor(Math.random() * colours.length)];
const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    render: (record) => (
      <div className="icon-name">
        <Avatar size="middle" style={{ backgroundColor: colour() }}>
          {record.charAt(0).toUpperCase()}
        </Avatar>
        <h4>{record}</h4>
      </div>
    ),
    width: "15%",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: (record) => (
      <div>
        {/* <NumberFormat value={record} displayType={'text'} format="(###) ###-#### " /> */}
        {record === "null" || record == null || record === "EXEMPT" ? (
          "-"
        ) : (
          <NumberFormat
            value={record}
            displayType={"text"}
            format="(###) ###-#### "
          />
        )}
      </div>
    ),
    width: "15%",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "25%",
  },
  {
    title: "Department",
    dataIndex: "department",
    render: (record) => (record ? record.name : "N/A"),
    key: "department",
    width: "10%",
  },
  {
    title: "Location",
    dataIndex: "location",
    render: (record) => (record ? record.name : "N/A"),
    width: "10%",
  },
  {
    title: "Seniority Level",
    dataIndex: "seniorityLevel",
    render: (record) => (record ? record.name : "N/A"),
    width: "15%",
  },
  {
    title: "EMP ID",
    dataIndex: "employeeId",
    width: "10%",
  },
];
const Employees = ({ loading, data, currentPage, setCurrentPage }) => {
  if (loading)
    return (
      <div
        style={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin
          style={{ float: "center" }}
          indicator={<LoadingOutlined />}
          size="large"
        />
      </div>
    );

  return (
    <div className="content-item-wrap employee-list">
      {data && (
        <Table
          bordered={false}
          columns={columns}
          dataSource={data.listEmployeeUnderAgency.data}
          className="custom-table"
          pagination={{
            current: currentPage,
            total: data.listEmployeeUnderAgency.count,
            hideOnSinglePage: true,
            defaultPageSize: 20,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
        />
      )}
    </div>
  );
};
export default withRouter(Employees);
