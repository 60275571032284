import React from 'react';
import AgencyLogo from '../../Images/agencyLogo.png'
import { Progress, Layout } from 'antd';
const Header = ({
     currentQuestion,
     totalQuestion,
     showProgress,
     progressPercentage,
     logo
    }) =>{
    return(
         <Layout.Header className='header survey_header'> 
                <div className="logo">
                    <img src={logo} />
                </div> 
                {showProgress && (
                    <div className="header-right">
                        <div>
                            <h4>Question<span>{currentQuestion}/{totalQuestion}</span></h4>
                        </div>
                        <div>
                            <Progress type="circle" strokeColor="#fff" strokeWidth="10" strokeLinecap="square" trailColor="#dd4b4b" percent={Math.ceil((progressPercentage/totalQuestion)*100)} width={50} />
                        </div>
                    </div>
                )}
 
        </Layout.Header>
    )
}
export default Header;