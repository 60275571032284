import DashIcon from '../../Images/dash-menu-icn.png'
import AgencyIcon from '../../Images/agency-menu-icn.png'
import SurveyIcon from '../../Images/survey-menu-icn.png'
import ReportIcon from '../../Images/rpt-menu-icn.png'

export const MenuNav = [
    {
        name:'Agency',
        icon:AgencyIcon,
        disabled:false,
        path:'/admin'
    }
]
