import { Switch } from "react-router-dom";
import PrivateRoute from "./private-routes";
import NotImplemented from "../../Modules/NotImplemented";
import Layout from "../../Modules/Layout";
import AgencyDetail from "../../Modules/AgencyDetail";
import SurveyDetail from "../../Modules/SurveyDetail";
import Agency from "../../Modules/Agency";
import PrintReport from "../../Modules/PrintReport/print";
import { showSideNav } from "../../Modules/SurveyDetail/var";
import Questions from "../../Modules/SurveyDetail/questions";
const AdminRoutes = () => {
  showSideNav(true);
  return (
    <Layout>
      <Switch>
        <PrivateRoute exact path="/admin" component={Agency} />
        <PrivateRoute exact path="/admin/agency" component={Agency} />
        <PrivateRoute exact path="/admin/agency/:id" component={AgencyDetail} />
        <PrivateRoute
          exact
          path="/admin/agency/:agencyId/:surveyId/questions"
          component={SurveyDetail}
        />
        <PrivateRoute
          exact
          path="/admin/agency/:agencyId/:surveyId/employees"
          component={SurveyDetail}
        />
        <PrivateRoute
          exact
          path="/admin/agency/:agencyId/:surveyId/report"
          component={SurveyDetail}
        />
        <PrivateRoute
          exact
          path="/admin/agency/:agencyId/:surveyId/print"
          component={PrintReport}
        />
      </Switch>
    </Layout>
  );
};

export default AdminRoutes;
