import fileDownload from "js-file-download";
import moment from "moment";

const getExport = (
  surveyId,
  departmentIds,
  roleIds,
  seniorityLevelIds,
  locationtIds,
  surveyName,
  setBtnLoad
) => {
  setBtnLoad(true);
  let data = {
    surveyId: surveyId.split(),
    departmentIds: departmentIds,
    locationIds: locationtIds,
    seniorityLevelIds: seniorityLevelIds,
    roleIds: roleIds,
  };

  let date = moment(new Date()).format("MMM DD YYYY hh:mm a");
  let filename = `${surveyName}_${date}.csv`;
  let url = process.env.REACT_APP_EXPORT_URL;
  const token = localStorage.getItem("@authToken");
  console.log("token", token);
  console.log("url", url);
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `${token}` : "",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.text())
    .then((data) => fileDownload(data, filename))
    .then(() => setBtnLoad(false));
  console.log("data", surveyName);
};

export default getExport;
