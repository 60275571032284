import React, { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_QUESTIONS_UNDER_SURVEY,
  GET_SURVEY_DETAILS,
} from "../../GraphQL/queries";
import AuthCheck from "./auth-check";
import AttendQuestions from "./attend-questions";
import Footer from "./footer";
import SaveSurvey from "./save-survey";
import Header from "./header";

const SurveyPage = ({ match }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSubmit, setShowSubmit] = useState(false);
  const [answers, setAnswers] = useState({});
  const [answerTime, setAnswerTime] = useState({});
  const [surveyStartTime, setSurveyStartTime] = useState(new Date());
  const [userId, setUserId] = useState(undefined);
  const [showComponent, setShowComponent] = useState("AUTHCHECK");
  const surveyData = useQuery(GET_SURVEY_DETAILS, {
    variables: {
      surveyId: match.params.surveyId.split(":")[1],
    },
  });
  const [errorText, setErrorText] = useState("");
  const [getQuestions, { error, loading, data }] = useLazyQuery(
    GET_QUESTIONS_UNDER_SURVEY,
    {
      onCompleted: (data) => {
        if (data.getSurveyQuestions.length > 0) {
          let obj = {};
          let dateObj = {};
          data.getSurveyQuestions.forEach((item) => {
            obj[item._id] = undefined;
            dateObj[item._id] = new Date().toISOString();
          });
          setAnswers(obj);
          setAnswerTime(dateObj);
          setShowComponent("ATTEND_QUESTIONS");
          setSurveyStartTime(new Date());
        } else if (data.getSurveyQuestions.length === 0) {
          setErrorText("There are no questions found for this survey.");
        }
        clearError();
      },
      onError: (err) => {
        console.log("error", err.message);
        if (err.message === "Already attended the survey") {
          setErrorText("The survey has been already attempted by the employee");
        } else {
          setErrorText("You dont have access to take the survey");
        }
        clearError();
      },
    }
  );
  const clearError = () => {
    setTimeout(() => {
      setErrorText("");
    }, 3000);
  };
  const searchEmployee = (val) => {
    if (val) {
      getQuestions({
        variables: {
          employeeId: val,
          surveyId: match.params.surveyId.split(":")[1],
        },
      });
    } else {
      getQuestions({
        variables: {
          surveyId: match.params.surveyId.split(":")[1],
        },
      });
    }
    setUserId(val);
  };

  const moveToNextQuestion = () => {
    if (currentIndex + 1 === data.getSurveyQuestions.length) {
      setShowSubmit(true);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const isAnonymous = surveyData.data
    ? surveyData.data.getSurveyWithAgency.anonymous
    : false;
  return (
    <div>
      <Header
        currentQuestion={currentIndex + 1}
        totalQuestion={data && data.getSurveyQuestions.length}
        showProgress={showComponent === "ATTEND_QUESTIONS"}
        progressPercentage={
          Object.values(answers).filter(
            (item) => item != undefined && item != ""
          ).length
        }
        logo={surveyData.data && surveyData.data.getSurveyWithAgency.agencyLogo}
      />
      {showComponent === "AUTHCHECK" && (
        <AuthCheck
          isAnonymous={isAnonymous}
          searchEmployee={searchEmployee}
          surveyTitle={
            surveyData.data && surveyData.data.getSurveyWithAgency.name
          }
          agencyName={
            surveyData.data && surveyData.data.getSurveyWithAgency.agencyName
          }
          surveyBgImage={
            surveyData.data &&
            surveyData.data.getSurveyWithAgency.surveyBackgroundImage
          }
          landingPageContent={
            surveyData.data &&
            surveyData.data.getSurveyWithAgency.landingPageContent
          }
          loading={loading}
          error={errorText}
        />
      )}
      {showComponent === "ATTEND_QUESTIONS" && (
        <AttendQuestions
          currentIndex={currentIndex}
          question={data.getSurveyQuestions[currentIndex]}
          answer={answers}
          ansTime={answerTime}
          setAnswerTime={(value) => {
            setAnswerTime(value);
          }}
          setAnswer={(value) => {
            setAnswers(value);
          }}
          showNext={moveToNextQuestion}
        />
      )}
      <SaveSurvey
        visible={showSubmit}
        isAnonymous={isAnonymous}
        onBack={() => setShowSubmit(false)}
        surveyId={match.params.surveyId.split(":")[1]}
        userId={userId}
        surveyResponse={answers}
        surveyAnswerTime={answerTime}
        surveyStartTime={surveyStartTime}
        agencyId={
          surveyData.data && surveyData.data.getSurveyWithAgency.agencyId
        }
      />
      {showComponent === "ATTEND_QUESTIONS" && (
        <Footer
          setIndex={setCurrentIndex}
          index={currentIndex}
          showNavIcons={showComponent === "ATTEND_QUESTIONS"}
          showNext={moveToNextQuestion}
        />
      )}
    </div>
  );
};
export default SurveyPage;
