import { Button, Modal, Row, Col, Table } from "antd";
import React, { useState, useEffect } from "react";
import { GET_QUESTION_RESPONSES } from "../../GraphQL/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CloseOutlined } from "@ant-design/icons";

const Responses = ({
  open,
  questionId,
  surveyId,
  selectedDepartment,
  selectedRole,
  selectedLocation,
  selectedSeniorityLevel,
  isPublic,
  toggleModal,
}) => {
  const columns = isPublic
    ? [
        {
          title: "Responses",
          dataIndex: "answers",
          key: "answers",
          render: (data) => {
            return <h4>{data[0].answerText}</h4>;
          },
        },
      ]
    : [
        {
          title: "Employee ID",
          dataIndex: "employeeDetails",
          key: "employeeDetails",
          render: (data) => {
            return <h4>{data.employeeId}</h4>;
          },
        },
        {
          title: "Responses",
          dataIndex: "answers",
          key: "answers",
          render: (data) => {
            return <h4>{data[0].answerText}</h4>;
          },
        },
      ];
  const [getResponses] = useLazyQuery(GET_QUESTION_RESPONSES, {
    onCompleted: (res) => {
      console.log(res);
      setData(res.listQuestionsUnderSurvey.data[0]);
    },
  });
  const [questionData, setData] = useState();
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (questionId && open) {
      getResponses({
        variables: {
          surveyId: surveyId,
          questionId: questionId,
          departmentIds: selectedDepartment,
          locationIds: selectedLocation,
          seniorityLevelIds: selectedSeniorityLevel,
          roleIds: selectedRole,
          skip: (page - 1) * 15,
          limit: 15,
        },
      });
    }
  }, [open, questionId, page]);

  return (
    <Modal
      width={"1000px"}
      onCancel={null}
      title=""
      placement="right"
      closable={true}
      visible={open}
      footer={null}
      centered={false}
      onCancel={() => {
        toggleModal(false);
        setPage(1);
      }}
      closeIcon={<CloseOutlined color="#fff" style={{ color: "white" }} />}
      bodyStyle={{ padding: "0px" }}
    >
      {questionData && (
        <>
          <Row>
            <Col span={24}>
              <div
                className="report-survey-detail"
                style={{ marginTop: "0px", borderRadius: "0px" }}
              >
                <div className="report-head h2">
                  <h2>{questionData.questionText}</h2>
                </div>
                <label className="report-label">
                  Answered: 1
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Skipped:
                  2
                </label>{" "}
              </div>
            </Col>
          </Row>
          <div style={{ maxHeight: "500px", overflow: "scroll" }}>
            <Table
              columns={columns}
              dataSource={questionData.employeeAnswers}
              className="custom-table1"
              pagination={{
                position: ["topCenter"],
                current: page,
                showSizeChanger: false,
                total: questionData.employeeAnswersCount,
                size: "small",
                onChange: (page) => setPage(page),
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
export default Responses;
