export const validateEmail = (value) =>/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
export const validator = (values) =>{
    let error={
        'email':values.email ? (!validateEmail(values.email)?'Email address is not valid':undefined):'Email address is required',
        'oldpassword':values.oldpassword? (values.oldpassword.length<8 ? 'Password should have less than 8 characters':undefined):'Password is required',
        'newpassword':values.newpassword? (values.newpassword.length<8 ? 'Password should have less than 8 characters':undefined):'New Password is required',
        'confirmpassword':values.confirmpassword? (values.confirmpassword !== values.newpassword ? 'New password and confirm password not match':undefined):'Confirm Password is required'
    }
    return error
}
