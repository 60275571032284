import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Card, Divider, Button, Popover } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Overview from "./overview";
import Employees from "./employees";
import Survey from "./survey";
import { useQuery } from "@apollo/client";
import {
  GET_CURRENT_AGENCY,
  GET_EMPLOYEES_BY_AGENCY,
} from "../../GraphQL/queries";
import EmpUpload from "./emp-upload-modal";
import employees from "./employees";
import SurveyDrawer from "./survey-drawer";
import { useReactiveVar } from "@apollo/client";
import { currentPage } from "../Layout/header-reactive-variables";
import CSVImg from "../../Images/csvSmall.png";
const { TabPane } = Tabs;

const image = <img src={CSVImg} />;
const Detail = ({ location }) => {
  const page = useReactiveVar(currentPage);
  const [showUpload, toggleUpload] = useState(false);
  const [empCurrentPage, setCurrentPage] = useState(1);
  const id = location.pathname.split(":")[1];

  const { loading, error, data } = useQuery(GET_CURRENT_AGENCY, {
    fetchPolicy: "cache-and-network",
    variables: { agencyId: id },
  });

  const employeeList = useQuery(GET_EMPLOYEES_BY_AGENCY, {
    variables: {
      agencyId: id,
      limit: 20,
      skip: (empCurrentPage - 1) * 20,
    },
  });

  useEffect(() => {
    currentPage("Agency Details");
  }, []);

  let [buttonShow, setButtonShow] = useState(false);
  let [addButtonShow, setAddButtonShow] = useState(false);
  let [isvisible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const viewButton = (key) => {
    if (key === "employee") {
      setButtonShow((buttonShow = true));
      setAddButtonShow((addButtonShow = false));
    } else if (key === "survey") {
      setAddButtonShow((addButtonShow = true));
      setButtonShow((buttonShow = false));
    } else {
      setButtonShow((buttonShow = false));
      setAddButtonShow((addButtonShow = false));
    }
  };
  return (
    <div className="agency-wrap">
      <div className="title-wrap">
        {data && data.getAgency.agencyName}
        <Popover
          placement="topLeft"
          content={image}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          {buttonShow && (
            <Button
              style={{ background: "#DD4B4B", color: "#fff", float: "right" }}
              type="primary"
              onClick={() => toggleUpload(true)}
            >
              IMPORT
            </Button>
          )}
        </Popover>
        {addButtonShow && (
          <Button
            onClick={showDrawer}
            style={{ background: "#DD4B4B", color: "#fff", float: "right" }}
          >
            <PlusOutlined />
            Add
          </Button>
        )}
      </div>
      <div style={{ background: "#FFFFFF" }} className="tab-wrap">
        <Tabs
          defaultActiveKey="1"
          size="medium"
          onChange={(e) => viewButton(e)}
        >
          <TabPane tab="Overview" key="overview">
            {data && <Overview data={data} />}
          </TabPane>
          <TabPane tab="Employees" key="employee">
            <Employees
              agencyId={id}
              loading={employeeList.loading}
              data={employeeList.data}
              currentPage={empCurrentPage}
              setCurrentPage={setCurrentPage}
            />
          </TabPane>
          <TabPane tab="Surveys" key="survey">
            <Survey agencyId={id} />
          </TabPane>
        </Tabs>
      </div>
      <SurveyDrawer
        agencyId={id}
        isvisible={isvisible}
        setVisible={setVisible}
      />
      <EmpUpload
        visible={showUpload}
        agencyId={id}
        toggleModal={toggleUpload}
        refetchEmpList={employeeList.refetch}
      />
    </div>
  );
};
export default Detail;
