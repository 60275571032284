import React, { useState, useEffect } from "react";
import {
  Drawer,
  Input,
  Select,
  notification,
  DatePicker,
  Button,
  Spin,
  Checkbox,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form, Field } from "react-final-form";
import { ADD_SURVEY_DETAILS } from "../../GraphQL/queries";
import { ADD_SURVEY, EDIT_SURVEY } from "../../GraphQL/mutations";
import { withRouter } from "react-router-dom";
import moment from "moment";

const SurveyDrawer = ({
  location,
  isvisible,
  setVisible,
  isEdit,
  editData,
  isLoading,
}) => {
  let title;
  let buttonName;
  const style = { color: "red" };
  const { Option } = Select;
  let defaultVal;
  if (isEdit) {
    title = "Edit Survey";
    buttonName = "Update";
    defaultVal = { ...editData };
  } else {
    title = "Add Survey";
    buttonName = "Add";
    defaultVal = {
      name: "",
      department: [],
      role: [],
      seniorityLevel: [],
      location: [],
      surveyEndDate: null,
    };
  }

  let defaultDate;
  if (defaultVal.surveyEndDate) {
    defaultDate = moment(new Date(Number(defaultVal.surveyEndDate)));
  } else {
    defaultDate = defaultVal.surveyEndDate;
  }
  const [isPublicSurvey, setIsPublicSurvey] = useState(true);
  useEffect(() => {
    if (isEdit && editData) {
      setIsPublicSurvey(editData.anonymous);
    } else {
      setIsPublicSurvey(true);
    }
  }, [isvisible, editData]);
  const agencyId = location.pathname.split(":")[1];
  let { data } = useQuery(ADD_SURVEY_DETAILS, {
    variables: {
      agencyId: agencyId,
    },
  });

  const [addSurvey] = useMutation(ADD_SURVEY, {
    refetchQueries: ["listSurveyUnderAgency"],
  });

  let [editSurvey] = useMutation(EDIT_SURVEY, {
    refetchQueries: ["listSurveyUnderAgency", "getSurveyById"],
  });

  const onClose = () => {
    setVisible(false);
  };

  const onSubmit = async (values) => {
    try {
      if (isEdit) {
        let endDate;
        if (!values.surveyEndDate._d) {
          endDate = moment(new Date(Number(values.surveyEndDate)));
        } else {
          endDate = moment(new Date(Number(values.surveyEndDate._d)));
        }

        setVisible(false);
        let result = await editSurvey({
          variables: {
            surveyId: editData._id,
            name: values.name,
            department: values.department,
            role: values.role,
            seniorityLevel: values.seniorityLevel,
            location: values.location,
            status: true,
            surveyEndDate: endDate._d,
          },
        });

        if (result.data.editSurvey.status) {
          notification.success({
            message: "Survey updated successfully",
            duration: 3,
          });
        }
      } else {
        setVisible(false);
        let result = await addSurvey({
          variables: {
            agencyId: agencyId,
            name: values.name,
            department: values.department,
            role: values.role,
            seniorityLevel: values.seniorityLevel,
            location: values.location,
            status: true,
            surveyEndDate: values.surveyEndDate._d,
            anonymous: isPublicSurvey,
          },
        });
        if (result.data.addSurvey.status) {
          notification.success({
            message: "Survey added successfully",
            duration: 3,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disabledDate = (currentDate) => {
    return currentDate && currentDate <= new Date();
  };

  let departments = [];
  let roles = [];
  let seniority = [];
  let locations = [];
  if (data) {
    departments = [...data.listDepartment];
    roles = [...data.listRole];
    seniority = [...data.listSeniority];
    locations = [...data.listLocation];
  }
  return (
    <div className="drawer-wrap">
      <Drawer
        title={title}
        width={440}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={isvisible}
      >
        {isLoading ? (
          <Spin
            style={{ textAlign: "center" }}
            indicator={<LoadingOutlined />}
            size="large"
          />
        ) : (
          <div className="form-wrap ">
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                values,
                pristine,
                submitting,
                submitError,
                form,
              }) => {
                // submit = handleSubmit;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="drawer-form">
                      <div className="nameField-wrap">
                        <Field
                          name="name"
                          //defaultValue={defaultVal.name}
                          initialValue={defaultVal.name}
                          validate={(value) =>
                            !value || value.length === 0
                              ? "Name field is required"
                              : undefined
                          }
                          render={({ input, meta }) => {
                            return (
                              <div
                                className="field"
                                style={{ marginBottom: "15px" }}
                              >
                                <Input
                                  {...input}
                                  placeholder="Name"
                                  style={{ borderRadius: "4px" }}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span style={style}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: "15px" }}>
                        <Field
                          name="surveyEndDate"
                          initialValue={defaultDate}
                          validate={(value) =>
                            !value || value.length === 0
                              ? "Survey End Date field is required"
                              : undefined
                          }
                          render={({ input, meta }) => {
                            return (
                              <div>
                                <DatePicker
                                  style={{ borderRadius: "4px" }}
                                  placeholder="Survey End Date"
                                  format="MM-DD-YYYY HH:mm"
                                  defaultValue={defaultDate}
                                  value={input.value}
                                  showTime
                                  showNow={false}
                                  disabledDate={disabledDate}
                                  onChange={(e) => input.onChange(e)}
                                  onBlur={(e) => input.onBlur(e)}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span style={style}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            );
                          }}
                        />
                      </div>
                      {!isEdit && (
                        <div style={{ marginBottom: "15px" }}>
                          <Checkbox
                            checked={isPublicSurvey}
                            onChange={(e) => {
                              setIsPublicSurvey(e.target.checked);
                              form.change("department", []);
                              form.change("role", []);
                              form.change("seniorityLevel", []);
                              form.change("location", []);
                            }}
                          >
                            Anonymous survey
                          </Checkbox>
                        </div>
                      )}

                      {!isPublicSurvey && (
                        <div>
                          <div style={{ marginBottom: "15px" }}>
                            <Field
                              name="department"
                              validate={(value) =>
                                isPublicSurvey
                                  ? undefined
                                  : !value || value.length === 0
                                  ? "Department field is required"
                                  : undefined
                              }
                              //defaultValue={defaultVal.department}
                              initialValue={defaultVal.department}
                              render={({ input, meta }) => {
                                return (
                                  <div id="department">
                                    <Select
                                      mode="multiple"
                                      showArrow
                                      filterOption
                                      optionFilterProp={"label"}
                                      maxTagCount={3}
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                      }}
                                      options={departments
                                        .sort((a, b) =>
                                          a.name > b.name ? 1 : -1
                                        )
                                        .map((department) => ({
                                          label: department.name,
                                          value: department._id,
                                        }))}
                                      placeholder="Department"
                                      value={input.value}
                                      onChange={(e) => input.onChange(e)}
                                      onBlur={(e) => input.onBlur(e)}
                                    />
                                    {(meta.error || meta.submitError) &&
                                      meta.touched && (
                                        <span style={style}>
                                          {meta.error || meta.submitError}
                                        </span>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          </div>
                          <div style={{ marginBottom: "15px" }}>
                            <Field
                              name="role"
                              initialValue={defaultVal.role}
                              validate={(value) =>
                                isPublicSurvey
                                  ? undefined
                                  : !value || value.length === 0
                                  ? "Role field is required"
                                  : undefined
                              }
                              render={({ input, meta }) => (
                                <div id="rol">
                                  <Select
                                    mode="multiple"
                                    showArrow
                                    allowClear
                                    filterOption
                                    optionFilterProp={"label"}
                                    maxTagCount={3}
                                    value={input.value}
                                    style={{
                                      width: "100%",
                                      borderRadius: "4px",
                                    }}
                                    placeholder="Role"
                                    options={roles
                                      .sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      )
                                      .map((role) => ({
                                        label: role.name,
                                        value: role._id,
                                      }))}
                                    onChange={(e) => input.onChange(e)}
                                    onBlur={(e) => input.onBlur(e)}
                                  />
                                  {(meta.error || meta.submitError) &&
                                    meta.touched && (
                                      <span style={style}>
                                        {meta.error || meta.submitError}
                                      </span>
                                    )}
                                </div>
                              )}
                            />
                          </div>
                          <div style={{ marginBottom: "15px" }}>
                            <Field
                              name="seniorityLevel"
                              initialValue={defaultVal.seniorityLevel}
                              validate={(value) =>
                                isPublicSurvey
                                  ? undefined
                                  : !value || value.length === 0
                                  ? "Seniority level field is required"
                                  : undefined
                              }
                              render={({ input, meta }) => (
                                <div id="seniorityLevel">
                                  <Select
                                    mode="multiple"
                                    showArrow
                                    allowClear
                                    filterOption
                                    optionFilterProp={"label"}
                                    maxTagCount={3}
                                    value={input.value}
                                    options={seniority.map((seniority) => ({
                                      label: seniority.name,
                                      value: seniority._id,
                                    }))}
                                    style={{
                                      width: "100%",
                                      borderRadius: "4px",
                                    }}
                                    placeholder="Seniority Level"
                                    onChange={(e) => input.onChange(e)}
                                    onBlur={(e) => input.onBlur(e)}
                                  />

                                  {(meta.error || meta.submitError) &&
                                    meta.touched && (
                                      <span style={style}>
                                        {meta.error || meta.submitError}
                                      </span>
                                    )}
                                </div>
                              )}
                            />
                          </div>
                          <div style={{ marginBottom: "15px" }}>
                            <Field
                              name="location"
                              initialValue={defaultVal.location}
                              validate={(value) =>
                                isPublicSurvey
                                  ? undefined
                                  : !value || value.length === 0
                                  ? "Location field is required"
                                  : undefined
                              }
                              render={({ input, meta }) => {
                                return (
                                  <div id="Location">
                                    <Select
                                      mode="multiple"
                                      showArrow
                                      allowClear
                                      filterOption
                                      optionFilterProp={"label"}
                                      options={locations
                                        .sort((a, b) =>
                                          a.name > b.name ? 1 : -1
                                        )
                                        .map((location) => ({
                                          label: location.name,
                                          value: location._id,
                                        }))}
                                      maxTagCount={3}
                                      value={input.value}
                                      style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                      }}
                                      placeholder="Location"
                                      onChange={(e) => {
                                        return input.onChange(e);
                                      }}
                                      onBlur={(e) => input.onBlur(e)}
                                    />
                                    {(meta.error || meta.submitError) &&
                                      meta.touched && (
                                        <span style={style}>
                                          {meta.error || meta.submitError}
                                        </span>
                                      )}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="btn-area">
                      <Button
                        onClick={(e) => {
                          form.restart();
                          //form.change("surveyEndDate", null);
                          onClose(e);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        style={{ background: "#DD4B4B", color: "#fff" }}
                        disabled={submitting}
                        onClick={(e) => {
                          form.resetFieldState("name");
                          form.resetFieldState("surveyEndDate");
                          handleSubmit(e);
                          // form.reset();

                          //return handleSubmit(e);
                        }}
                      >
                        <PlusOutlined />
                        {buttonName}
                      </Button>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default withRouter(SurveyDrawer);
