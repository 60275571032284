import React, { useState,useEffect } from 'react';
import { Modal, Button, notification } from 'antd';
import UploadImg from '../../Images/upload.png'
import CSVImg from '../../Images/csv.png'
import {CloseOutlined} from '@ant-design/icons'
import {useMutation} from '@apollo/client'
import {IMPORT_EMPLOYEES} from '../../GraphQL/mutations'
const EmpUpload = ({visible,agencyId,toggleModal,refetchEmpList}) => {
    const[selectedFile,setSelected] = useState(null)
    const[uploadData,setUploadData] = useState(null)
    const [importData,{data,loading,error}] = useMutation(IMPORT_EMPLOYEES)
    useEffect(() =>{
        setSelected(null)  
        setUploadData(null)
    },[visible])
    const submitData = async () =>{
        try{
            let res = await importData({
                variables:{
                    file:selectedFile,
                    agencyId:agencyId
                }
            })
            setUploadData(res.data.importEmployees.data)
            notification.success({
                message: 'Import Success',
                description:
                  'Employee has been imported succesfully',
                duration: 3
              });
              refetchEmpList()
        }catch(err){
            notification.error({
                message: 'Upload Error',
                description:'No Employee records were found within the specified CVS file.',
                duration: 3
              });
        }
    }
    const onChange = ({
        target: {
          files: [file]
        }
    }) => setSelected(file)
  return (
    <Modal
        title="Import Employees"
        visible={visible}
        footer={null}
        className='upload-modal'
        onCancel={() =>toggleModal(false)}
    >
        {!uploadData ? (
            <div className='pre-upload'>
                {!selectedFile ? (
                    <div className='browse-wrap'>
                        <img src={UploadImg} />
                        <p>Select the Employee List Document</p>
                        <p style = {{ color: "red", fontSize:12 }}>*Import File Should be in CSV Format</p>
                        <input 
                            type='file'
                            id='file-upload'
                            multiple={false}
                            onChange={onChange}
                            accept=".csv"
                        />
                        <Button className='btn' onClick={() =>document.getElementById('file-upload').click()}>Browse</Button>
                    </div>
                ):(
                    <div className='upload-wrap'>
                        <img src={CSVImg} />
                        <p>{selectedFile.name}</p>
                        <div className='action-wrap'>
                            <Button className='remove-btn' onClick={() =>setSelected(undefined)} disabled={loading}>Remove</Button>
                            <Button className='upload-btn' onClick={() =>submitData()} loading={loading} disabled={loading}>Upload</Button>
                        </div>
                    </div>
                )}
        </div>
        ):(
            <div className='post-upload'>
                <div className='post-content-wrap'>
                    <h4>Import Status</h4>
                    <div className='status-text'>
                        <p>Total Employees uploaded - {uploadData.createdEmployees+uploadData.updatedEmployees}</p>
                        <p>Total Created employees - {uploadData.createdEmployees}</p>
                        <p>Total Updated employees - {uploadData.updatedEmployees}</p>
                    </div>
                    <Button className='btn' onClick={() =>toggleModal(false)}>Done</Button>
                </div>
            </div>
        )}
      </Modal>
  );
};

export default EmpUpload;