import { Redirect, Route} from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import {authVar} from '../ReactiveVariables'
import Logo from "../../Images/logo.png";
const PrivateRoute = ({component: Component,...rest}) => {
    const auth = useReactiveVar(authVar);
    return (
      <Route
        {...rest}
        render={(props) => {
            if(auth.loaded && auth.auth){
                return <Component {...props} />
            }else if(auth.loaded && !auth.auth){
                return <Redirect to='/login' />
            }else{
              return <div className='site-load-wrp'><img src={Logo} alt='logo' /></div>
            }
        }}
      />
    )
  }
export default PrivateRoute;
  