import React, { useEffect } from "react";
import { Table, Avatar, Menu, Dropdown } from "antd";
import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_AGENCIES } from "../../GraphQL/queries";
import { withRouter, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import { useReactiveVar } from "@apollo/client";
import { currentPage } from "../Layout/header-reactive-variables";
const colours = [
  "#ffa940",
  "#ff4d4f",
  "#fa541c",
  "#ffd666",
  "#ffec3d",
  "#d3f261",
  "#69c0ff",
  "#13c2c2",
  "#95de64",
  "#ad6800",
  "#fffb8f",
  "#5b8c00",
  "#ffa39e",
  "#ffd8bf",
  "#ffe7ba",
  "#1d39c4",
  "#531dab",
  "#eb2f96",
];
const colour = () => colours[Math.floor(Math.random() * colours.length)];
const Agency = () => {
  const history = useHistory();
  const page = useReactiveVar(currentPage);
  const { loading, data, error, refetch } = useQuery(GET_AGENCIES, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      limit: 10,
    },
  });
  useEffect(() => {
    currentPage("Agency");
    refetch();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "agencyName",
      render: (record) => (
        <div className="icon-name">
          <Avatar size="middle" style={{ backgroundColor: "#C6AADA" }}>
            {record.charAt(0).toUpperCase()}
          </Avatar>
          <h4>{record}</h4>
        </div>
      ),
      width: "15%",
      key: "agencyName",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      render: (record) => (
        <span>{record.firstName + " " + record.lastName}</span>
      ),
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (record) => (
        <div>
          {/* <NumberFormat value={record} displayType={'text'} format="(###) ###-#### " /> */}
          {record === "null" || record == null || record === "EXEMPT" ? (
            "-"
          ) : (
            <NumberFormat
              value={record}
              displayType={"text"}
              format="(###) ###-#### "
            />
          )}
        </div>
      ),
      width: "15%",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "25%",
      key: "email",
    },
    {
      title: "Surveys",
      dataIndex: "surveyCount",
      width: "10%",
      align: "center",
      key: "surveyCount",
    },
    {
      title: "Employees",
      dataIndex: "employeeCount",
      width: "10%",
      align: "center",
      key: "employeeCount",
    },
    {
      title: "",
      key: "id",
      width: "10%",
      align: "center",
      render: (obj) => (
        // <Dropdown
        //     overlay={ <Menu>
        //                 {/* <Menu.Item key="0">Edit</Menu.Item>
        //                 <Menu.Divider /> */}
        //                 <Menu.Item key="1" onClick={() =>history.push(`/admin/agency/:${obj._id}`)}>Detail</Menu.Item>
        //             </Menu>}
        //     trigger={['click']}
        // >
        //     <a href className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <a href>
          <EyeOutlined />
        </a>
        //     </a>
        // </Dropdown>
      ),
    },
  ];
  return (
    <div className="content-item-wrap">
      {data && (
        <Table
          bordered={false}
          columns={columns}
          dataSource={data.listAgency.data}
          className="custom-table"
          rowClassName="agency-table-row"
          pagination={false}
          onRow={(obj) => ({
            onClick: () => {
              history.push(`/admin/agency/:${obj._id}`);
            },
          })}
          trigger={["click"]}
          onClick={(e) => e.preventDefault()}
        />
      )}
    </div>
  );
};
export default Agency;
