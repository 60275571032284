import React from "react";
import { Layout } from "antd";
import Header from "./header";
import Menu from "./menu";
import { authVar } from "../../Routes/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { showSideNav } from "../SurveyDetail/var";
const LayoutWrap = (props) => {
  const showSideNavVar = useReactiveVar(showSideNav);
  const auth = useReactiveVar(authVar);
  return (
    <Layout>
      {auth.loaded && showSideNavVar ? (
        <>
          <Header />
          <Layout>
            <Menu />
            <Layout.Content>{props.children}</Layout.Content>
          </Layout>
        </>
      ) : (
        <Layout.Content>{props.children}</Layout.Content>
      )}
    </Layout>
  );
};
export default LayoutWrap;
