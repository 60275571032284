import React from "react";
import { Table, Button } from "antd";

const TextBody = ({
  answers,
  isPrint,
  totalResponses,
  isPublic,
  onShowAllResponses,
}) => {
  const columns = isPublic
    ? [
        {
          title: "Responses",
          dataIndex: "answers",
          key: "answers",
          render: (data) => {
            return <h4>{data[0].answerText}</h4>;
          },
        },
      ]
    : [
        {
          title: "Employee ID",
          dataIndex: "employeeDetails",
          key: "employeeDetails",
          render: (data) => {
            return <h4>{data.employeeId}</h4>;
          },
        },
        {
          title: "Responses",
          dataIndex: "answers",
          key: "answers",
          render: (data) => {
            return <h4>{data[0].answerText}</h4>;
          },
        },
      ];

  return (
    <div className="content-item-wrap1 employee-list">
      <Table
        columns={columns}
        dataSource={answers}
        className="custom-table1"
        pagination={false}
      />
      {answers.length > totalResponses && (
        <div style={{ paddingTop: "15px", float: "right" }}>
          <Button type="primary" onClick={() => onShowAllResponses()}>
            Show all responses
          </Button>
        </div>
      )}
    </div>
  );
};

export default TextBody;
