import React from 'react';
import {Col } from 'antd';
import LoginImage from '../../Images/login-img.png'
const LeftPane = () =>{
    return(
        <Col span={12} className='left-wrap'>
            <div className='left-upper-wrap'>
                <img src={LoginImage} />
            </div>
            <div className='left-bottom-wrap'>
                <div className='text-wrap'>
                    <h2>Welcome to Culture in Transit</h2>
                    <h4>The Industry Leading Tool to Benchmark Your Safety Culture</h4>
                    <p style={{textAlign:'center',maxWidth:'100%',paddingLeft:10,paddingRight:10, textTransform:'none'}}>Research and experience show that when safety culture is strong, accidents are less frequent and less severe. Culture in Transit measures an organization’s current safety culture and analyzes it across locations, departments, and roles in order to have a comprehensive view of what is working and what might not be working. </p>
                </div>
            </div>
      </Col>
    )
}
export default LeftPane;