import { makeVar } from "@apollo/client";

export const showSideNav = makeVar();
showSideNav(true);

export const printVar = makeVar();
printVar({
  surveyData: null,
  departmentIds: [],
  locationIds: [],
  seniorityLevelIds: [],
  roleIds: [],
});
