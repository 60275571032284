import React, { useState } from 'react'
import { Layout, Avatar, Menu, Dropdown, Modal, Button, Input, notification } from 'antd';
import { EyeOutlined, LogoutOutlined } from "@ant-design/icons";
import { validator } from './operations'
import { Form, Field } from 'react-final-form'
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../GraphQL/mutations";

const style = { color: "red" };
const eye = <EyeOutlined />;
export const ChangePassword = (props) => {
  const [changepasswordVisible, setChangePasswordVisible] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [conformationVisible, setConformationVisible] = useState(false);

  const showChangePassword = () => {
    setChangePasswordVisible(true);
  };

  const onClose = () => {
    setChangePasswordVisible(false);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const showConformation = () => {
    setConformationVisible(true);
  };

  const handleOk = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const handleCancel = () => {
    setConformationVisible(false);
  };


  const menu = (
    <Menu>
      {/* <Menu.Item key="1" onClick={() => showChangePassword()} >
        Change Password
        </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="2" onClick={() => showConformation()} >
        <LogoutOutlined />
        &nbsp;&nbsp;
        Log Out
    </Menu.Item>

    </Menu>
  );
  const [changePassword, { loading, data, error }] = useMutation(CHANGE_PASSWORD);

  const handleChange = async (data) => {
    try {
      const resp = await changePassword({
        variables: {
          email: data.email,
          oldpassword: data.oldpassword,
          newpassword: data.newpassword,
          confirmpassword: data.confirmpassword,
        },
      });

      console.log("response", resp);
      if (resp.data.changePassword.status)
        notification.success({
          message: "Password has been changed successfully",
          duration: 3,
        });
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Password Is Incorrect!", duration: 3 });
    }
  };
  return (
    <Layout.Header className='header'>
      <Dropdown overlay={menu}>
        <Avatar>A</Avatar>
      </Dropdown>
      <Modal title="Change Password"
        closable={false}
        visible={changepasswordVisible}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}>
        <i onClick={togglePasswordVisiblity}>{eye}</i>
        <Form
          onSubmit={(data) => handleChange(data)}
          validate={values => validator(values)}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='field-item'>
              <Field
                    autoFocus
                    name="email"
                  >
                    {({ input, meta }) => (
                      <div>
                        <Input
                          {...input}
                          allowClear
                          style={{ width: "100%", borderRadius: "4px" }}
                          size='large'
                          component="input"
                          type="email"
                          placeholder="Email"
                        />
                        {(meta.error || meta.submitError) &&
                        meta.touched && (
                          <span style={style}>
                            {meta.error || meta.submitError}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  </div>
                  <br />
                <div className='field-item'>
                <Field
                  autoFocus
                  name="oldpassword"
                >
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        allowClear
                        style={{ width: "100%", borderRadius: "4px" }}
                        size='large'
                        component="input"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Current Password"
                      />
                      {(meta.error || meta.submitError) &&
                        meta.touched && (
                          <span style={style}>
                            {meta.error || meta.submitError}
                          </span>
                        )}
                    </div>
                  )}
                </Field>
              </div>
              <br />
              <div className='field-item'>
                <Field
                  autoFocus
                  name="newpassword"
                >
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        allowClear
                        style={{ width: "100%", borderRadius: "4px" }}
                        size='large'
                        component="input"
                        type={passwordShown ? "text" : "password"}
                        placeholder="New Password"
                      />
                      {(meta.error || meta.submitError) &&
                        meta.touched && (
                          <span style={style}>
                            {meta.error || meta.submitError}
                          </span>
                        )}
                    </div>
                  )}
                </Field>
              </div>
              <br />
              <div className='field-item'>
                <Field
                  autoFocus
                  name="confirmpassword"
                >
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        allowClear
                        style={{ width: "100%", borderRadius: "4px" }}
                        size='large'
                        component="input"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Confirm Password"
                      />
                      {(meta.error || meta.submitError) &&
                        meta.touched && (
                          <span style={style}>
                            {meta.error || meta.submitError}
                          </span>
                        )}
                    </div>
                  )}
                </Field>
              </div>
              <div className="btn-area survey-btn-area">
                <Button onClick={(e) => { form.restart(); onClose(e); }}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                  </Button>
                <Button
                  type="submit"
                  style={{ background: "#DD4B4B", color: "#fff", marginRight: 8 }}
                  block
                  size="large"
                  loading={loading}
                  disabled={submitting || pristine}
                  onClick={() => {
                    form.resetFieldState("email");
                    form.resetFieldState("oldpassword");
                    form.resetFieldState("newpassword");
                    form.resetFieldState("confirmpassword");
                    handleSubmit();
                    form.reset();
                  }}
                >
                  Submit
                  </Button>
              </div>
            </form>
          )}
        />
      </Modal>
      <Modal title="Logout?" visible={conformationVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you want to logout from application?</p>
      </Modal>
    </Layout.Header>
  )
}
// export default ChangePassword;