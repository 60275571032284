import { gql } from "@apollo/client";
export const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      _id
      firstName
      lastName
      fullName
      email
      role
      profileImage
    }
  }
`;

export const GET_AGENCIES = gql`
  query agencyList($skip: Int!, $limit: Int!) {
    listAgency(skip: $skip, limit: $limit) {
      count
      data {
        _id
        agencyName
        logo
        address
        website
        phone
        email
        contactPerson {
          firstName
          lastName
          email
          phone
          profileImage
        }
        surveyCount
        employeeCount
        createdDate
        status
      }
    }
  }
`;

export const GET_CURRENT_AGENCY = gql`
  query getCurrentAgency($agencyId: String!) {
    getAgency(agencyId: $agencyId) {
      _id
      agencyName
      logo
      address
      website
      phone
      email
      contactPerson {
        firstName
        lastName
        email
        phone
        profileImage
      }
      surveyCount
      employeeCount
    }
  }
`;

export const GET_SURVEY_BY_ID = gql`
  query getSurveyById($surveyId: String!) {
    getSurveyById(surveyId: $surveyId) {
      _id
      name
      agencyId
      anonymous
      totalResponse
      surveyResponse
      agency {
        _id
        agencyName
        logo
      }
      departments {
        _id
        name
      }
      roles {
        _id
        name
      }
      locations {
        _id
        name
      }
      seniorityLevels {
        _id
        name
      }
      surveyEndDate
    }
  }
`;

export const GET_SURVEY_QUESTIONS = gql`
  query listQuestionsUnderSurvey($surveyId: String!) {
    listQuestionsUnderSurvey(surveyId: $surveyId, skip: 0, limit: 100) {
      data {
        _id
        agencyId
        surveyId
        questionText
        type
        answers {
          _id
          answerText
          isCorrect
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_AGENCY = gql`
  query listEmployeeUnderAgency($agencyId: String!, $skip: Int!, $limit: Int!) {
    listEmployeeUnderAgency(agencyId: $agencyId, skip: $skip, limit: $limit) {
      count
      data {
        _id
        employeeId
        agencyId
        firstName
        lastName
        fullName
        phone
        email
        department {
          _id
          name
        }
        location {
          _id
          name
        }
        seniorityLevel {
          _id
          name
        }
      }
    }
  }
`;

// export const DEPARTMENT_LIST = gql`
// query listDepartment{
//   listDepartment{
//     _id,
//     name
//   }
// }
// `;

// export const ROLE_LIST = gql`
// query listRole{
//   listRole{
//     _id,
//     name
//   }
// }
// `;

export const ADD_SURVEY_DETAILS = gql`
  query addSurveyDetails($agencyId: String!) {
    listDepartment(agencyId: $agencyId) {
      _id
      name
    }
    listRole(agencyId: $agencyId) {
      _id
      name
    }
    listSeniority(agencyId: $agencyId) {
      _id
      name
    }
    listLocation(agencyId: $agencyId) {
      _id
      name
    }
  }
`;

export const GET_SURVEYS_BY_AGENCY = gql`
  query listSurveyUnderAgency($agencyId: String!, $skip: Int!, $limit: Int!) {
    listSurveyUnderAgency(agencyId: $agencyId, skip: $skip, limit: $limit) {
      count
      data {
        _id
        name
        surveyStartDate
        surveyEndDate
        respondents
        response
        anonymous
        completion
        questions
        totalResponse
      }
    }
  }
`;

export const GET_SURVEYS_BY_ID = gql`
  query getSurveyById($surveyId: String!) {
    getSurveyById(surveyId: $surveyId) {
      _id
      name
      agencyId
      department
      role
      location
      anonymous
      seniorityLevel
      surveyEndDate
    }
  }
`;

export const GET_EMPLOYEES_BY_SURVEY = gql`
  query listEmployeeUnderSurvey($surveyId: String!, $skip: Int!, $limit: Int!) {
    listEmployeeUnderSurvey(surveyId: $surveyId, skip: $skip, limit: $limit) {
      count
      data {
        status
        employeeDetails {
          _id
          fullName
          email
          employeeId
          phone
        }
      }
    }
  }
`;

export const GET_REPORTS_HEADER = gql`
  query reportsHeader(
    $surveyId: String!
    $departmentIds: [String]
    $locationIds: [String]
    $seniorityLevelIds: [String]
    $roleIds: [String]
  ) {
    reportsHeader(
      surveyId: $surveyId
      departmentIds: $departmentIds
      locationIds: $locationIds
      seniorityLevelIds: $seniorityLevelIds
      roleIds: $roleIds
    ) {
      avgTimeTookForSurvey
      employeesRespondedCount
      targetEmployeesCount
      pendingEmployeesCount
      completion
      totalResponse(
        surveyId: $surveyId
        departmentIds: $departmentIds
        locationIds: $locationIds
        seniorityLevelIds: $seniorityLevelIds
        roleIds: $roleIds
      )
    }
  }
`;

export const GET_REPORTS_DATA = gql`
  query reports(
    $surveyId: String!
    $departmentIds: [String]
    $locationIds: [String]
    $seniorityLevelIds: [String]
    $roleIds: [String]
    $skip: Int!
    $limit: Int!
  ) {
    reports(
      surveyId: $surveyId
      departmentIds: $departmentIds
      locationIds: $locationIds
      seniorityLevelIds: $seniorityLevelIds
      roleIds: $roleIds
      skip: $skip
      limit: $limit
    ) {
      count
      data {
        _id
        type
        questionText
        answeredCount
        skipedCount
        answers {
          answerText
          responseCount
          employeeId
        }
      }
    }
  }
`;

export const GET_REPORTS_DATA_NEW = gql`
  query reportWithPagination(
    $surveyId: String!
    $questionId: String
    $departmentIds: [String]
    $locationIds: [String]
    $seniorityLevelIds: [String]
    $roleIds: [String]
    $skip: Int!
    $limit: Int!
  ) {
    listQuestionsUnderSurvey(
      questionId: $questionId
      surveyId: $surveyId
      limit: $limit
      skip: $skip
    ) {
      data {
        _id
        type
        questionText
        employeesAttemptedSurveyCount(
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        )
        employeesAttemptedQuestionCount(
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        )
        answers {
          answerText
          isCorrect
          responseCount(
            departmentIds: $departmentIds
            locationIds: $locationIds
            seniorityLevelIds: $seniorityLevelIds
            roleIds: $roleIds
          )
        }
        employeeAnswers(
          skip: 0
          limit: 10
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        ) {
          answers {
            answerText
          }
          employeeDetails {
            employeeId
          }
        }
        employeeAnswersCount
      }
      count
    }
  }
`;
export const GET_QUESTION_RESPONSES = gql`
  query getQuestionResponses(
    $surveyId: String!
    $questionId: String
    $departmentIds: [String]
    $locationIds: [String]
    $seniorityLevelIds: [String]
    $roleIds: [String]
    $skip: Int!
    $limit: Int!
  ) {
    listQuestionsUnderSurvey(
      questionId: $questionId
      surveyId: $surveyId
      limit: 1
      skip: 0
    ) {
      data {
        _id
        type
        questionText
        employeesAttemptedSurveyCount(
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        )
        employeesAttemptedQuestionCount(
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        )
        employeeAnswers(
          skip: $skip
          limit: $limit
          departmentIds: $departmentIds
          locationIds: $locationIds
          seniorityLevelIds: $seniorityLevelIds
          roleIds: $roleIds
        ) {
          answers {
            answerText
          }
          employeeDetails {
            employeeId
          }
        }
        employeeAnswersCount
      }
    }
  }
`;

export const GET_QUESTIONS_UNDER_SURVEY = gql`
  query getSurveyQuestions($employeeId: String, $surveyId: String!) {
    getSurveyQuestions(employeeId: $employeeId, surveyId: $surveyId) {
      _id
      agencyId
      surveyId
      questionText
      mandatory
      points
      type
      answers {
        answerText
        _id
      }
    }
  }
`;

export const GET_SURVEY_DETAILS = gql`
  query getSurveyWithAgency($surveyId: String!) {
    getSurveyWithAgency(surveyId: $surveyId) {
      name
      agencyName
      agencyLogo
      agencyId
      surveyBackgroundImage
      anonymous
      landingPageContent
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;
