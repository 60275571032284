import React from "react";
import { Table } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ChoiceBody = ({ answers, answerdCount, isPrint }) => {
  const columns = [
    {
      title: "Answer Choices",
      dataIndex: "answerText",
      key: "answerText",
    },
    {
      title: "Responses",
      dataIndex: "responseCount",
      key: "responseCount",
    },
    {
      title: "Response Percentage",
      dataIndex: "responseCount",
      render: (record, total) => {
        if (answerdCount === 0) return <div>0.00%</div>;
        return <div>{((record * 100) / answerdCount).toFixed(2)}%</div>;
      },
      key: "responsePercentage",
    },
  ];

  let responses = [];
  let answerChoices = [];
  answers.forEach((answer) => {
    answerChoices.push(answer.answerText);
    responses.push(answer.responseCount);
  });

  const options = {
    chart: {
      type: "bar",
    },
    title: {
      text: " ",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: answerChoices,
      colors: ["#A6CEE3"],
    },
    yAxis: {
      tickInterval: 50,
      title: {
        text: "Responses",
      },
    },
    plotOptions: {
      series: {
        pointWidth: 30,
      },
    },

    series: [
      {
        name: "",

        data: responses,
      },
    ],
  };

  return (
    <div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="content-item-wrap1 employee-list">
        <Table
          columns={columns}
          dataSource={answers}
          className="custom-table1"
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ChoiceBody;
