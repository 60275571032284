import React, { useEffect } from "react";
import CalenderIcon from "../../Images/cal-icon.png";
import GlobIcon from "../../Images/glob-icon.png";
import copylink from "../../Images/copylink.png";
import { Tabs, notification, Tag } from "antd";
import Questions from "./questions";
import Employees from "./employees";
import { useQuery } from "@apollo/client";
import { GET_SURVEY_BY_ID } from "../../GraphQL/queries";
import moment from "moment";
import Report from "./report";
import { useReactiveVar } from "@apollo/client";
import { currentPage } from "../Layout/header-reactive-variables";
import Clipboard from "react-clipboard.js";
import { Link, withRouter, Redirect, Route } from "react-router-dom";
import { ADD_SURVEY_DETAILS } from "../../GraphQL/queries";

const SurveyDetail = ({ match, history }) => {
  console.log("match", history);
  const page = useReactiveVar(currentPage);
  const survey = match.params.surveyId.split(":")[1];
  const agencyId = match.params.agencyId.split(":")[1];

  let surveyURI;
  const { data, loading, error } = useQuery(GET_SURVEY_BY_ID, {
    variables: {
      surveyId: survey,
    },
  });
  const agencyData = useQuery(ADD_SURVEY_DETAILS, {
    variables: { agencyId: agencyId },
  });
  const copyLinkSuccess = () => {
    notification.success({ message: "Link copied to clipboard", duration: 3 });
  };
  useEffect(() => {
    currentPage("Survey Details");
  }, []);

  const getActiveKey = () => {
    const path =
      history.location.pathname.split("/")[
        history.location.pathname.split("/").length - 1
      ];
    if (path === "questions") return "1";
    if (path === "employees") return "2";
    if (path === "report") return "3";
  };

  const handleChange = (e) => {
    switch (e) {
      case "1":
        history.replace(
          `/admin/agency/:${data.getSurveyById.agencyId}/:${survey}/questions`
        );
        break;
      case "2":
        history.replace(
          `/admin/agency/:${data.getSurveyById.agencyId}/:${survey}/employees`
        );
        break;
      case "3":
        history.replace(
          `/admin/agency/:${data.getSurveyById.agencyId}/:${survey}/report`
        );
        break;
      default:
        history.replace(
          `/admin/agency/:${data.getSurveyById.agencyId}/:${survey}/questions`
        );
    }
  };
  if (data) {
    surveyURI = `https://${data.getSurveyById.agency.agencyName
      .toLowerCase()
      .replace(/\s/g, "")}.${
      process.env.REACT_APP_SURVEY_DOMAIN
    }/survey/:${survey}`;
  }
  return (
    <div className="survey-detail">
      {data && (
        <>
          <div className="survey-detail-header-wrap">
            <div className="survey-detail-header-content">
              <h2>{data.getSurveyById.name}</h2>
              <div className="survey-detail-header-sub-text">
                <div className="survey-detail-sub-text">
                  <img src={GlobIcon} alt="globe icon" />
                  <p>
                    {data.getSurveyById.agency.agencyName}/
                    {data.getSurveyById.departments
                      .map((item) => item.name)
                      .join(", ")}
                  </p>
                </div>
                <div className="survey-detail-sub-text">
                  <img src={CalenderIcon} alt="calender icon" />
                  <p>
                    {moment(
                      new Date(Number(data.getSurveyById.surveyEndDate))
                    ).format("MMM DD YYYY hh:mm a")}
                  </p>
                </div>
              </div>
              {data && data.getSurveyById.anonymous && (
                <Tag color="#dd4c4b">Anonymous survey</Tag>
              )}
            </div>
            <div className="survey-detail-header-actions">
              <Clipboard
                className="link-btn"
                data-clipboard-text={surveyURI}
                onClick={() => copyLinkSuccess()}
              >
                <img src={copylink} alt="copy icon" />
                &nbsp;COPY LINK
              </Clipboard>
              {/* <Button className='link-btn' onClick={() => copyLinkSuccess()}><img src={copylink} />&nbsp;COPY LINK</Button> */}
              <div
                className="status-tag"
                style={{
                  backgroundColor:
                    new Date() <
                    new Date(Number(data.getSurveyById.surveyEndDate))
                      ? "#DD9200"
                      : "#43875E",
                }}
              >
                <h4>
                  {new Date() <
                  new Date(Number(data.getSurveyById.surveyEndDate))
                    ? "Scheduled"
                    : "Completed"}
                </h4>
              </div>
            </div>
          </div>
          <div className="survey-tab-wrap">
            <Tabs
              activeKey={getActiveKey()}
              onChange={(e) => handleChange(e)}
              tabBarStyle={{ margin: "0px" }}
            >
              <Tabs.TabPane tab="QUESTIONS" key="1">
                <Questions surveyId={survey} />
              </Tabs.TabPane>
              {data && !data.getSurveyById.anonymous && (
                <Tabs.TabPane tab="EMPLOYEES" key="2">
                  <Employees
                    surveyId={survey}
                    agencyId={data.getSurveyById.agencyId}
                  />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane tab="REPORT" key="3">
                <Report
                  surveyId={survey}
                  filter={true}
                  agencyData={agencyData.data}
                  isPublic={data ? data.getSurveyById.anonymous : false}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};
export default withRouter(SurveyDetail);
