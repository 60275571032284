import React from 'react'
import { Layout } from 'antd';
import HubspireLogo from '../../Images/menu-hubspire-logo.png'
import {NavLink,withRouter} from 'react-router-dom'
import {MenuNav} from './menu-options'
const { Sider } = Layout;
const Menu = (props) =>{
    return(
        <Sider className='menu'>
            {MenuNav.map((menu) =>{
                return(
                        <NavLink 
                            to={!menu.disabled && menu.path} 
                            activeClassName='selected-menu' 
                            className='menu-item' 
                            key={menu.name} 
                        >
                            <img src={menu.icon} alt={`${menu.name}-icon`} />
                            <h4>{menu.name}</h4>
                        </NavLink> 
                )
            })}
            <div className='hub-logo'>
                <h6>Powered by</h6>
                <img src={HubspireLogo} alt='hubspire-logo' />
            </div>
        </Sider>
    )
}
export default withRouter(Menu);