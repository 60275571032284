import React, { useState } from "react";
import { Button } from "antd";
import { Row, Col, Menu, Dropdown, Spin, Tag } from "antd";
import { EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import CalenderIcon from "../../Images/cal-icon.png";
import { withRouter } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_SURVEYS_BY_AGENCY,
  GET_SURVEYS_BY_ID,
} from "../../GraphQL/queries";
import moment from "moment";
import SurveyDrawer from "./survey-drawer";

const Survey = ({ history, agencyId }) => {
  let [isvisible, setVisible] = useState(false);
  let [currentSurveyId, setCurrentSurveyId] = useState(null);
  let [editData, setEditData] = useState(null);
  const showDrawer = () => {
    setVisible(true);
  };

  let [getSurvey, getSurveyData] = useLazyQuery(GET_SURVEYS_BY_ID, {
    variables: {
      surveyId: currentSurveyId,
    },
    fetchPolicy: "network-only",
  });

  const [loadMore, setLoadMore] = useState(10);
  const { data, loading, error, refetch } = useQuery(GET_SURVEYS_BY_AGENCY, {
    variables: {
      agencyId,
      skip: 0,
      limit: loadMore,
    },
  });
  if (loading)
    return (
      <div>
        <Spin
          style={{ float: "center" }}
          indicator={<LoadingOutlined />}
          size="large"
        />
      </div>
    );

  return (
    <div>
      {data &&
        data.listSurveyUnderAgency.data.map((survey, index) => {
          return (
            <div className="survey-agency-detail">
              <Row key={index}>
                <Col
                  span={9}
                  xs={{ order: 1 }}
                  sm={{ order: 2 }}
                  md={{ order: 3 }}
                  lg={{ order: 1 }}
                  className="survey-head"
                >
                  <Row>
                    <Col span={24}>
                      <h2>{survey.name}</h2>
                    </Col>
                    <Col span={24}>
                      <div className="survey-date">
                        <h4>
                          <img src={CalenderIcon} alt="calender" />
                          {moment(
                            new Date(Number(survey.surveyEndDate))
                          ).format("MMM DD YYYY hh:mm a")}
                        </h4>
                      </div>
                      {survey.anonymous && (
                        <Tag color="#dd4c4b">Anonymous survey</Tag>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={3}
                  xs={{ order: 2 }}
                  sm={{ order: 1 }}
                  md={{ order: 4 }}
                  lg={{ order: 2 }}
                >
                  <Row span={9}>
                    <h2>{survey.totalResponse}</h2>
                  </Row>
                  <Row span={15}>
                    <h4>Response</h4>
                  </Row>
                </Col>
                <Col
                  span={4}
                  xs={{ order: 3 }}
                  sm={{ order: 4 }}
                  md={{ order: 2 }}
                  lg={{ order: 3 }}
                >
                  <Row span={9}>
                    <h2>{survey.questions}</h2>
                  </Row>
                  <Row span={15}>
                    <h4>Questions</h4>
                  </Row>
                </Col>
                <Col
                  span={4}
                  xs={{ order: 4 }}
                  sm={{ order: 3 }}
                  md={{ order: 1 }}
                  lg={{ order: 4 }}
                >
                  {!survey.anonymous && (
                    <>
                      <Row span={9}>
                        <h2>{survey.completion.toFixed(2)} %</h2>
                      </Row>
                      <Row span={15}>
                        <h4>Completion Rate</h4>
                      </Row>
                    </>
                  )}
                </Col>
                <Col
                  span={3}
                  xs={{ order: 5 }}
                  sm={{ order: 3 }}
                  md={{ order: 1 }}
                  lg={{ order: 5 }}
                  className="status-col"
                >
                  <div
                    className="status-tag"
                    style={{
                      backgroundColor:
                        new Date() < new Date(Number(survey.surveyEndDate))
                          ? "#DD9200"
                          : "#43875E",
                    }}
                  >
                    <span style={{ color: "white" }}>
                      {new Date() < new Date(Number(survey.surveyEndDate))
                        ? "Scheduled"
                        : "Completed"}
                    </span>
                  </div>
                </Col>
                <Col
                  span={1}
                  xs={{ order: 6 }}
                  sm={{ order: 3 }}
                  md={{ order: 1 }}
                  lg={{ order: 6 }}
                  className="more-col"
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          onClick={(e) => {
                            // console.log('e', e);
                            setCurrentSurveyId((currentSurveyId = survey._id));
                            //currentSurveyId = survey._id;
                            getSurvey();
                            //if (getSurveyData.loading) return <div>Loading..</div>
                            //console.log('surveyData', getSurveyData);
                            // if (getSurveyData.data) {
                            //   setEditData(
                            //     (editData = {
                            //       ...getSurveyData.data.getSurveyById,
                            //     })
                            //   );
                            // }
                            // console.log('editData', getSurveyData.data);
                            return showDrawer(e);
                          }}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          key="1"
                          onClick={() =>
                            history.push(
                              `/admin/agency/:${agencyId}/:${survey._id}/questions`
                            )
                          }
                        >
                          Detail
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      href
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <EllipsisOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </div>
          );
        })}
      {getSurveyData.data ? (
        <SurveyDrawer
          agencyId={agencyId}
          isvisible={isvisible}
          setVisible={setVisible}
          isEdit={true}
          editData={getSurveyData.data.getSurveyById}
        />
      ) : (
        <SurveyDrawer
          isLoading={getSurveyData.loading}
          isvisible={isvisible}
          isEdit={true}
        />
      )}
      {console.log("dataError", data.listSurveyUnderAgency.count)}
      {loadMore < data.listSurveyUnderAgency.count && (
        <Button
          style={{
            background: "#DD4B4B",
            color: "#fff",
            float: "left",
            border: "none",
          }}
          type="primary"
          onClick={() => {
            setLoadMore(loadMore + 10);
            refetch();
          }}
        >
          Load more...
        </Button>
      )}
    </div>
  );
};
export default withRouter(Survey);
